import { WhiteCardContainer, FormFieldsContainer } from '../../components';
import useDoctorUsers from './hooks/useDoctorUsers';
import { SimpleDataTable } from './components/SimpleDataTable';
import { ProfileFormHeader } from './components/ProfileFormHeader';
import DoctorUserDetails from './components/DoctorUserDetails/DoctorUserDetails';

const DoctorUsers = () => {
  const { userTableInstance, isLoading, selectedUser, _closeUserView, userStatusCatalog, documentTypes, onSuccessUserUpdate } = useDoctorUsers();

  return (
    <WhiteCardContainer>
      {JSON.stringify(selectedUser) === '{}' && (
        <FormFieldsContainer>
          <ProfileFormHeader title="Usuarios Doctores" />
        </FormFieldsContainer>
      )}
      {!isLoading && userTableInstance && JSON.stringify(selectedUser) === '{}' && <SimpleDataTable tableInstance={userTableInstance} />}
      
      {!isLoading && JSON.stringify(selectedUser) !== '{}' && (
        <DoctorUserDetails
          selectedUser={selectedUser}
          onCancel={_closeUserView}
          onSuccessfulSave={onSuccessUserUpdate}
          selectionMode={'edit'}
          userStatusCatalog={userStatusCatalog}
          documentTypes={documentTypes}
        />
      )}
    </WhiteCardContainer>
  );
};

export default DoctorUsers;
