import { useState } from 'react';
import { ProfileFormHeader } from '../ProfileFormHeader';
import HalfRow from '../../../../components/formElements/HalfRow';
import FormInputField from '../../../../components/formElements/FormInputField';
import FullRow from '../../../../components/formElements/FullRow';
import useUserDetails from './hooks/useUserDetails';
import CustomRedAlert from '../../../../components/alerts/customRedAlert/CustomRedAlert';
import CustomSuccessModal from '../../../../components/alerts/customSuccessModal/CustomSuccessModal';
import { CalendarIcon, PhoneIcon, UsersIcon, EnvelopeIcon, KeyIcon } from '@heroicons/react/24/outline';
import FormSelectDrop from '../../../../components/formElements/FormSelectDrop';

export default function WebUserDetails(props) {
    const { selectedUser, onCancel, onSuccessfulSave } = props;

    const [openReturnAlert, setOpenReturnAlert] = useState(false);

    const {
        handleSubmit,
        register,
        errors,
        isDirty,
        closeErrorMessage,
        onCloseSuccessMessage,
        showSuccessModal,
        showErrorModal,
        errorBody,
        successBody,
        onSaveSubmit,
        isEditing,
        validatePasswordConfirmation
    } = useUserDetails(selectedUser, onSuccessfulSave);

    const _onReturnToUsers = () => {
        if (isDirty) {
            setOpenReturnAlert(true);
        } else {
            onCancel();
        }
    };

    const _onConfirmCancelAppointment = () => {
        setOpenReturnAlert(false);
        onCancel();
    };

    const _onCancelConfirmationForClosing = () => {
        setOpenReturnAlert(false);
    };

    return (
        <form onSubmit={handleSubmit(onSaveSubmit)}>
            <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                    {!isEditing ? (
                        <ProfileFormHeader title={'Crear nuevo Usuario Web'} />
                    ) : (
                        <ProfileFormHeader title={'Editar Usuario Web'} />
                    )}
                    <div>
                        <h3 className="text-lg leading-6 font-medium text-dl-secondary">
                            {isEditing ? 'Edición de Usuario Web' : 'Nuevo registro'}
                        </h3>
                    </div>
                    <div className="grid grid-cols-6 gap-6">
                        <HalfRow>
                            <FormInputField
                                identifier="firstName"
                                label="Nombre"
                                iconComponent={<UsersIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                                type="string"
                                registration={register('firstName', {
                                    required: true
                                })}
                            />
                        </HalfRow>
                        <HalfRow>
                            <FormInputField
                                identifier="lastName"
                                label="Apellido"
                                iconComponent={<UsersIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                                type="string"
                                registration={register('lastName', {
                                    required: true
                                })}
                            />
                        </HalfRow>
                        <FullRow>
                            <FormInputField
                                identifier="email"
                                label="Dirección de email"
                                iconComponent={<EnvelopeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                                type="string"
                                registration={register('email', {
                                    required: true
                                })}
                                disabled={isEditing}
                            />
                        </FullRow>
                        {isEditing && (
                            <>
                                <HalfRow>
                                    <FormInputField
                                        identifier="creation"
                                        label="Creación"
                                        type="string"
                                        registration={register('creation', {
                                            required: true
                                        })}
                                        disabled
                                    />
                                </HalfRow>
                                <HalfRow></HalfRow>
                            </>
                        )}
                        <HalfRow>
                            <FormInputField
                                identifier="newPassword"
                                label="Nueva contraseña"
                                iconComponent={<KeyIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                                type="password"
                                registration={register('newPassword', {
                                    required: !isEditing
                                })}
                            />
                            {errors.newPassword?.type === 'required' ? (
                                <p className="text-red-700 font-light">Inserte nueva contraseña</p>
                            ) : null}
                        </HalfRow>
                        <HalfRow>
                            <FormInputField
                                identifier="newPasswordConfirm"
                                label="Confirmar nueva contraseña"
                                iconComponent={<KeyIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                                type="password"
                                registration={register('newPasswordConf', {
                                    required: !isEditing,
                                    validate: validatePasswordConfirmation
                                })}
                            />
                            {errors.newPasswordConf?.type === 'required' ? (
                                <p className="text-red-700 font-light">Confirme contraseña</p>
                            ) : null}
                            {errors.newPasswordConf?.type === 'validate' ? (
                                <p className="text-red-700 font-light">Las contraseñas deben coincidir</p>
                            ) : null}
                        </HalfRow>
                        <HalfRow>
                            <FormSelectDrop
                                label={'Rol del usuario'}
                                identifier={'idRole'}
                                options={[
                                    { id: 1, label: 'Super Admin' },
                                    { id: 2, label: 'Admin' }
                                ]}
                                defaultValue={selectedUser?.idRole || null}
                                defaultOption={false}
                                helperText={'Requerido'}
                                registration={register('idRole', { required: true })}
                                errorMessage={errors.idRole ? 'Este campo es requerido' : null}
                            />
                        </HalfRow>
                        <HalfRow>
                            <FormSelectDrop
                                label={'Estado del usuario'}
                                identifier={'status'}
                                options={[
                                    { id: true, label: 'Activo' },
                                    { id: false, label: 'Inactivo' }
                                ]}
                                defaultValue={selectedUser?.status || null}
                                defaultOption={false}
                                helperText={'Requerido'}
                                registration={register('status', { required: true })}
                                errorMessage={errors.status ? 'Este campo es requerido' : null}
                            />
                        </HalfRow>
                    </div>
                </div>
                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                    <button
                        type="button"
                        onClick={_onReturnToUsers}
                        className="mr-2 rounded-full border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-dl-accent focus:ring-offset-2"
                    >
                        Regresar
                    </button>
                    <button
                        type="submit"
                        disabled={!isDirty}
                        className="disabled:bg-dl-primary-700 bg-dl-primary-900 border border-transparent rounded-full shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dl-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dl-primary-700"
                    >
                        {!isEditing ? 'Guardar' : 'Actualizar'}
                    </button>
                </div>
            </div>

            {openReturnAlert && (
                <CustomRedAlert
                    open={openReturnAlert}
                    title={'¿Desea descartar los cambios?'}
                    bodyText={'El usuario ha sido modificado, por favor confirme que desea salir de esta vista.'}
                    onClose={_onCancelConfirmationForClosing}
                    mainActionText={'Salir'}
                    onMainAction={_onConfirmCancelAppointment}
                    secondaryActionText={'Cancelar'}
                    onSecondaryAction={_onCancelConfirmationForClosing}
                />
            )}

            {showErrorModal && (
                <CustomRedAlert
                    open={showErrorModal}
                    title={'Ocurrió un error.'}
                    bodyText={errorBody}
                    onClose={closeErrorMessage}
                />
            )}

            {showSuccessModal && (
                <CustomSuccessModal
                    title={'Registro actualizado con éxito'}
                    bodyText={successBody}
                    buttonText={'Aceptar'}
                    buttonAction={onCloseSuccessMessage}
                    open={showSuccessModal}
                    onClose={onCloseSuccessMessage}
                />
            )}
        </form>
    );
}
