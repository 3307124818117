import { useForm } from 'react-hook-form';
import FormInputField from '../../../../components/formElements/FormInputField';
import { CxTabButton, CxCard, CxWrap, Line } from '../Sx';
import { useEffect, useState } from 'react';
import { InfoService } from '../../../../services/DoctorProfile';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import CustomSuccessModal from '../../../../components/alerts/customSuccessModal/CustomSuccessModal';
const phoneRegExp = /^([+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*|\s*)$/;
const formSchema = z.object({
  contact: z.string().regex(phoneRegExp, { message: 'Numero de telefono invalido' }).max(15, { message: 'El teléfono debe contener como máximo 15 números' }).min(7, { message: 'El teléfono debe contener al menos 7 números' }).or(z.literal("")),
  whatsapp: z.string().regex(phoneRegExp, { message: 'Numero de telefono invalido' }).max(12, { message: 'El teléfono debe contener como máximo 12 números' }).min(7, { message: 'El teléfono debe contener al menos 7 números' }).or(z.literal("")),
  instagram: z.string().url({ message: 'URL invalida' }).or(z.literal("")),
  facebook: z.string().url({ message: 'URL invalida' }).or(z.literal("")),
  twitter: z.string().url({ message: 'URL invalida' }).or(z.literal("")),
  linkedin: z.string().url({ message: 'URL invalida' }).or(z.literal("")),
  website: z.string().url({ message: 'URL invalida' }).or(z.literal("")),
  otherLink: z.string().url({ message: 'URL invalida' }).or(z.literal(""))
});
const Info = (selectedUser) => {
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successBody, setSuccessBody] = useState('');
  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState({})
  const { register, handleSubmit, formState, getValues, reset } = useForm({ resolver: zodResolver(formSchema) });
  const { errors } = formState;
  const _createSuccessMessage = (message) => {
    setShowSuccessModal(true);
    setSuccessBody(message);
  };
  const onCloseSuccessMessage = () => {
    setShowSuccessModal(false);
    setSuccessBody('');
  };
  useEffect(() => {
    InfoService.getInfo(selectedUser.selectedUser.id).then((res) => {
      if (res.data[0]) {
        setData(res.data[0])
        reset({
          contact: res.data[0].contact,
          facebook: res.data[0].facebook,
          instagram: res.data[0].instagram,
          twitter: res.data[0].twitter,
          linkedin: res.data[0].linkedin,
          otherLink: res.data[0].otherLink,
          website: res.data[0].website,
          whatsapp: res.data[0].whatsapp,
        });
      }
    });
  }, [refresh]);
  const saveForm = (values) => {
    const { idDoctorProfile, id } = data
    InfoService.updateInfo(selectedUser.selectedUser.id, { ...values, idDoctorProfile, id }).then((res) => {
      _createSuccessMessage('Idioma actualizado para el perfil.')
      setRefresh(!refresh);
    });
  };

  return (
    <form onSubmit={handleSubmit(saveForm)}>
      <CxWrap>
        <CxCard>Contacto</CxCard>
        <Line />
        <CxCard>
          <div className="flex flex-col gap-2 w-1/2">
            <FormInputField identifier="contacto" label="Contacto 1" type="string" registration={register('contact')}
              errorMessage={errors.contact?.message} />
            <FormInputField identifier="Whatsapp" label="Whatsapp" type="string" registration={register('whatsapp')}
              errorMessage={errors.whatsapp?.message} />
          </div>
        </CxCard>
      </CxWrap>
      <CxWrap>
        <CxCard>Redes Sociales</CxCard>
        <Line />
        <CxCard>
          <div className="flex flex-col gap-2 w-1/2">
            <FormInputField identifier="instagram" label="Instagram" type="string" registration={register('instagram')}
              errorMessage={errors.instagram?.message} />
            <FormInputField identifier="facebook" label="Facebook" type="string" registration={register('facebook')}
              errorMessage={errors.facebook?.message} />
            <FormInputField identifier="twitter" label="X" type="string" registration={register('twitter')}
              errorMessage={errors.twitter?.message} />
            <FormInputField identifier="linkedin" label="LinkedIn" type="string" registration={register('linkedin')}
              errorMessage={errors.linkedin?.message} />
          </div>
        </CxCard>
      </CxWrap>
      <CxWrap>
        <CxCard>Pagina personal</CxCard>
        <Line />
        <CxCard>
          <div className="flex flex-col gap-2 w-1/2">
            <FormInputField identifier="web" label="Sitio web" type="string" registration={register('website')}
              errorMessage={errors.website?.message} />
            <FormInputField identifier="link" label="Otro link" type="string" registration={register('otherLink')}
              errorMessage={errors.otherLink?.message} />
          </div>
        </CxCard>
      </CxWrap>
      <div className="px-4 py-3  text-right sm:px-6">
        <CxTabButton label="Actualizer" />
      </div>
      {showSuccessModal && (
        <CustomSuccessModal
          title={'Acción ejecutada con éxito!'}
          bodyText={successBody}
          buttonText={'Cerrar'}
          buttonAction={onCloseSuccessMessage}
          open={showSuccessModal}
          onClose={onCloseSuccessMessage}
        />
      )}
    </form>
  );
};

export default Info;
