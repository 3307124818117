import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { format, parseISO } from 'date-fns';
import updateProfileApproval, { getSubscriptionList } from '../api/updateProfileApproval';

export default function useProfileApproval(profileApproval, queryProfileApproval, selectedUser, userInfo, queryUsers) {
    const [isLoading, setIsLoading] = useState(true);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState('');
    const [successBody, setSuccessBody] = useState('');
    const [subList, setSubList] = useState([])

    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
        reset,
        setValue,
        getValues
    } = useForm();

    useEffect(() => {
        reset({
            ...profileApproval,
            creation: profileApproval?.creation && format(parseISO(profileApproval.creation), 'yyyy-MM-dd H:mm:ss')
        });
    }, [profileApproval, reset]);

    const _createErrorMessage = (errorMessage) => {
        setShowErrorModal(true);
        setErrorBody(errorMessage);
    };

    const closeErrorMessage = () => {
        setShowErrorModal(false);
        setErrorBody('');
    };

    const _createSuccessMessage = (errorMessage) => {
        setShowSuccessModal(true);
        setSuccessBody(errorMessage);
    };

    const onCloseSuccessMessage = () => {
        setShowSuccessModal(false);
        setSuccessBody('');
        queryProfileApproval();
        queryUsers();
    };

    const saveApproval = async (data) => {
        const executeUpdate = await updateProfileApproval(
            selectedUser.id,
            {
                ...data,
                idApproval: data.id,
                idWebUser: userInfo.id,
                subscription: data?.subscription,
                status: String(data.status) === 'true' ? true : false
            },
            _createErrorMessage
        );

        if (executeUpdate) {
            _createSuccessMessage('¡Se ha actualizado el registro con éxito!');
        }
    };


    const getSubList = async () => {
        try {
            const res = await getSubscriptionList()
            let list = res?.map((val) => ({ label: val?.title, value: (val?.title)?.toLowerCase() }))
            setSubList(list)
        } catch (error) {
            console.log(error)
        }
    }


    useEffect(() => {
        getSubList()
    }, [])

    console.log("watch", getValues())

    return {
        register,
        handleSubmit,
        errors,
        watch,
        reset,
        setValue,
        saveApproval,
        isLoading,
        showErrorModal,
        showSuccessModal,
        errorBody,
        closeErrorMessage,
        onCloseSuccessMessage,
        successBody,
        subList
    };
}
