import WhiteCardContainer from '../../../../../components/containers/forms/WhiteCardContainer';
import FormFieldsContainer from '../../../../../components/containers/forms/FormFieldsContainer';
import ProfileFormHeader from '../../ProfileFormHeader/ProfileFormHeader';
import HalfRow from '../../../../../components/formElements/HalfRow';
import FormInputField from '../../../../../components/formElements/FormInputField';
import { CurrencyDollarIcon, MapPinIcon, PhoneIcon } from '@heroicons/react/24/solid';
import FormFieldset from '../../../../../components/formElements/FormFieldset';
import FormCheckBox from '../../../../../components/formElements/FormCheckBox';
import FullRow from '../../../../../components/formElements/FullRow';
import FormTextArea from '../../../../../components/formElements/FormTextArea';
import FormSelectDrop from '../../../../../components/formElements/FormSelectDrop';
import FormFieldsFooter from '../../../../../components/containers/forms/FormFieldsFooter';
import FormButton from '../../../../../components/formElements/FormButton';
import CustomRedAlert from '../../../../../components/alerts/customRedAlert/CustomRedAlert';
import CustomSuccessModal from '../../../../../components/alerts/customSuccessModal/CustomSuccessModal';
import useProfileApproval from './hooks/useProfileApproval';
import { useEffect } from 'react';

export default function ProfileApproval({ profileApproval, queryProfileApproval, selectedUser, userInfo, queryUsers }) {
    console.log(profileApproval)
    const {
        handleSubmit,
        errors,
        register,
        saveApproval,
        showErrorModal,
        showSuccessModal,
        errorBody,
        closeErrorMessage,
        onCloseSuccessMessage,
        successBody,
        setValue,
        subList
    } = useProfileApproval(profileApproval, queryProfileApproval, selectedUser, userInfo, queryUsers);

    if (!profileApproval) {
        return null;
    }


    const handleSelect = (e) => {

    }


    const profileTypeOptions = [
        { id: 'informational', label: 'Informational Only' },
        { id: 'withAppointment', label: 'Informational with Appointment' }
    ]

    console.log("profileApproval", profileApproval)

    return (
        <form action="#" method="POST" onSubmit={handleSubmit(saveApproval)}>
            <WhiteCardContainer>
                <FormFieldsContainer>
                    <ProfileFormHeader
                        title={'Aprobar el perfil del doctor'}
                        initialText={'Si toda la información del doctor esta correcta, puedes proceder a aprobarlo'}
                    />

                    <div className="grid grid-cols-6 gap-6">
                        <HalfRow>
                            <FormInputField
                                identifier={'profileApprovalTitle'}
                                label={'Título de la aprobación'}
                                type={'text'}
                                placeholder={'Ej. Aprobación para el doctor'}
                                helperText={'Requerido'}
                                registration={register('profileApprovalTitle', { required: true })}
                                errorMessage={errors.profileApprovalTitle ? 'Este campo es requerido' : null}
                            />
                        </HalfRow>

                        <FullRow>
                            <FormTextArea
                                label={'Descripción de la aprobación'}
                                identifier={'profileApprovalDescription'}
                                rows={3}
                                placeholder={'Ej. Describir porque se da esta aprobación a este doctor'}
                                helperText={
                                    'Información relevante sobre el por que se le permite al médico ser aprobado.'
                                }
                                registration={register('profileApprovalDescription', { required: true })}
                                errorMessage={errors.profileApprovalDescription ? 'Este campo es requerido' : null}
                            />
                        </FullRow>

                        <FullRow>
                            <FormTextArea
                                label={'Notas internas sobre este doctor.'}
                                identifier={'notes'}
                                rows={3}
                                placeholder={'Ej. Este doctor falta revisar algo nuevo'}
                                helperText={'Notas internas para este perfil.'}
                                registration={register('notes', { required: true })}
                                errorMessage={errors.notes ? 'Este campo es requerido' : null}
                            />
                        </FullRow>

                        <HalfRow>
                            <FormInputField
                                identifier="creation"
                                label="Creación"
                                type="string"
                                registration={register('creation', {
                                    required: true
                                })}
                                disabled
                            />
                        </HalfRow>

                        <HalfRow>
                            <FormInputField
                                label={'Última actualización hecha por Admin:'}
                                type={'text'}
                                value={`${profileApproval?.webUserDetails?.firstName} ${profileApproval?.webUserDetails?.lastName} - ${profileApproval?.webUserDetails?.email}`}
                                identifier={'webUser'}
                                disabled
                            />
                        </HalfRow>
                        <HalfRow>
                            <FormSelectDrop
                                label={'Tipo de perfil'}
                                identifier={'profileType'}
                                options={profileTypeOptions}
                                defaultValue={profileApproval.profileType}
                                defaultOption={'informational'}
                                helperText={'Requerido'}
                                registration={register('profileType', { required: true })}
                                errorMessage={errors.profileType ? 'Este campo es requerido' : null}
                                disabled={true}
                            />
                        </HalfRow>

                        <HalfRow>
                            <FormSelectDrop
                                label={'Estado de la aprobación del perfil'}
                                identifier={'status'}
                                options={[
                                    { id: true, label: 'Activo' },
                                    { id: false, label: 'Inactivo' }
                                ]}
                                defaultValue={profileApproval.status}
                                defaultOption={false}
                                helperText={'Requerido'}
                                registration={register('status', { required: true })}
                                errorMessage={errors.status ? 'Este campo es requerido' : null}
                            />
                        </HalfRow>

                        <HalfRow>
                            {/* <FormSelectDrop
                                label={'Suscripción'}
                                identifier={'subscription'}
                                defaultOption={'subscription'}
                                options={subList}
                                defaultValue={profileApproval.subscription}
                                helperText={'Requerido'}
                                registration={register('subscription', { required: true })}
                                errorMessage={errors.subscription ? 'Este campo es requerido' : null}
                                onSelect={e => handleSelect(e)}
                            /> */}



                            {subList ?
                                <>

                                    <label htmlFor="subscription" className="block text-sm font-medium text-gray-700">Suscripción</label>
                                    <select
                                        id={"subscription"}
                                        name={"subscription"}
                                        // defaultValue={profileApproval?.subscription || ''}
                                        {...register('subscription', { required: true })}
                                        onChange={(e) => {
                                            const selectedValue = e.target.value;
                                            console.log(selectedValue)
                                            setValue("subscription", selectedValue);

                                            if (selectedValue === "lite") {
                                                setValue('profileType', profileTypeOptions[0]?.id);
                                            } else if (selectedValue === "pro") {
                                                setValue('profileType', profileTypeOptions[1]?.id);
                                            } else {
                                                setValue('profileType', "");
                                            }
                                        }}
                                        // key={profileApproval?.subscription || ''}
                                        // value={profileApproval?.subscription || ''}
                                        className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-dl-primary-500 focus:border-dl-primary-500 sm:text-sm"
                                    >
                                        <option key="empty-select-value" value="">
                                            --- Seleccione --
                                        </option>
                                        {subList?.map((opt) => {
                                            return (
                                                <option key={opt.label} value={opt.value} selected={opt.value == profileApproval?.subscription ? "selected" : ""}>
                                                    {opt.label}
                                                </option>
                                            );
                                        })}
                                    </select>
                                    {errors.subscription ? <p className="mt-2 text-sm text-red-700">Este campo es requerido</p> : null}
                                </>
                                : ""
                            }


                        </HalfRow>
                    </div>
                </FormFieldsContainer>
                <FormFieldsFooter>
                    <FormButton label="Actualizar" type={'submit'} />
                </FormFieldsFooter>
            </WhiteCardContainer>
            {showErrorModal && (
                <CustomRedAlert
                    open={true}
                    title={`Ocurrió un error.`}
                    bodyText={errorBody}
                    onClose={closeErrorMessage}
                />
            )}

            {showSuccessModal && (
                <CustomSuccessModal
                    title={'Aprobación actualizada con éxito'}
                    bodyText={successBody}
                    buttonText={'Actualizar Aprobación'}
                    buttonAction={onCloseSuccessMessage}
                    open={showSuccessModal}
                    onClose={onCloseSuccessMessage}
                />
            )}
        </form>
    );
}
