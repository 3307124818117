import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

const useLogin = (performLogin) => {
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState('');
  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm();

  const onSubmit = handleSubmit(async ({ email, password}) => {
    setLoginError('');
    const data = await performLogin(email, password);
    if(data.ok === false) {
      setLoginError('Error al iniciar sesión, revise correo y contraseña e intente de nuevo');
    } else {
      navigate('/users');
    }
  });

  return {
    errors,
    onSubmit,
    register,
    loginError
  }
};

export default useLogin;
