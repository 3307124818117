import { getCookie } from './cookie';

const API = process.env.REACT_APP_API_SERVER;
const SESSION_NAME = process.env.REACT_APP_SESSION_NAME;

const HTTP_CODES = {
    SERVER_ERROR: 500,
    NOT_FOUND: 404,
    BAD_REQUEST: 400
};

const responseHandler = async (response, onFail) => {
    const responseError = Object.values(HTTP_CODES).includes(response.status);

    if (responseError) {
        if (onFail) return onFail();
    }

    let returnValue;
    try {
        returnValue = await response.text();
        returnValue = JSON.parse(returnValue);
    } catch (e) {
        returnValue = response;
    }

    return returnValue;
};

export async function authenticatedRequest(url, options, onFail) {
    // const sessionToken = getCookie(SESSION_NAME);
    const sessionToken = localStorage.getItem('adminToken');
    if (!sessionToken) {
        console.error('No session found');
        return { status: 401, message: 'Not Authenticated' };
    }

    const authenticatedOptions = { ...options };

    if (!authenticatedOptions.headers) {
        authenticatedOptions.headers = {};
    }

    authenticatedOptions.headers['Authorization'] = `Bearer ${sessionToken}`;

    if (options.method !== 'GET' && authenticatedOptions.headers['Content-Type'] === undefined) {
        authenticatedOptions.headers['Content-Type'] = 'application/json';
    }

    let response;
    try {
        response = await fetch(urlFormatter(url), authenticatedOptions);
    } catch (error) {
        if (onFail) return onFail(error);
        return;
    }

    return responseHandler(response, onFail);
}

export async function authenticatedDelete(url, onFail) {
    return authenticatedRequest(url, { method: 'DELETE' }, onFail);
}

export async function authenticatedFetch(url, onFail) {
    return authenticatedRequest(url, { method: 'GET' }, onFail);
}

export async function authenticatedPost(url, onFail, payload = null) {
    return authenticatedRequest(url, { method: 'POST', body: payload ? JSON.stringify(payload) : null }, onFail);
}

export async function authenticatedPut(url, onFail, payload = null) {
    return authenticatedRequest(url, { method: 'PUT', body: payload ? JSON.stringify(payload) : null }, onFail);
}

export async function authenticatedPatch(url, onFail, payload = null) {
    return authenticatedRequest(url, { method: 'PATCH', body: payload ? JSON.stringify(payload) : null }, onFail);
}

export const urlFormatter = (path) => {
    if (path.indexOf('http://') === 0 || path.indexOf('https://') === 0) {
        return path;
    }
    return `${API}${path}`;
};
