import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import createEducation from '../api/createEducation';
import updateEducation from '../api/updateEducation';

export default function useDoctorEducation(queryAllDoctorEducations, selectedUser) {
    const [isLoading, setIsLoading] = useState(true);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState('');
    const [successBody, setSuccessBody] = useState('');
    const [defaultValues, setDefaultValues] = useState({});
    const [selectedEducation, setSelectedEducation] = useState(null);

    const {
        register,
        handleSubmit,
        formState: { errors, isDirty },
        watch,
        reset,
        setValue
    } = useForm(defaultValues);

    useEffect(() => {
        setIsLoading(true);

        if (selectedEducation) {
            setDefaultValues(selectedEducation);
            reset(selectedEducation);

            setValue('status', selectedEducation.status ? 'true' : 'false', { shouldValidate: true });
        }

        setIsLoading(false);
    }, [reset, selectedEducation, setValue]);

    const resetState = () => {
        setShowSuccessModal(false);
        setShowErrorModal(false);
        setErrorBody('');
        setSuccessBody('');
        setDefaultValues({});
        setSelectedEducation(null);
        reset({ educationName: '', educationDetail: '' });
        setValue('status', '', { shouldValidate: true });
    };

    const _createErrorMessage = (errorMessage) => {
        setShowErrorModal(true);
        setErrorBody(errorMessage);
    };

    const closeErrorMessage = () => {
        setShowErrorModal(false);
        setErrorBody('');
    };

    const _createSuccessMessage = (errorMessage) => {
        setShowSuccessModal(true);
        setSuccessBody(errorMessage);
    };

    const saveEducation = (data) => {
        if (!data) {
            _createErrorMessage('No se pudo obtener información válida para guardar. Inténtelo de nuevo.');
            return;
        }

        if (JSON.stringify(defaultValues) === '{}') {
            executeCreateEducation(data);
        } else {
            const adjustedData = { ...data, idEducation: data.id, status: data.status === 'true' };
            doUpdateEducation(adjustedData);
        }
    };

    const executeCreateEducation = async (newEducation) => {
        setIsLoading(true);
        const createNewEducation = await createEducation(selectedUser.id, newEducation, _createErrorMessage);

        if (createNewEducation) {
            _createSuccessMessage('¡Se ha guardado el registro con éxito!');
        }

        setIsLoading(false);
    };

    const doUpdateEducation = async (updatedEducationObject) => {
        setIsLoading(true);
        const executeUpdatedEducation = await updateEducation(
            selectedUser.id,
            updatedEducationObject,
            _createErrorMessage
        );

        if (executeUpdatedEducation) {
            _createSuccessMessage('¡Se ha actualizado el registro con éxito!');
        }

        setIsLoading(false);
    };

    const onCloseSuccessMessage = () => {
        resetState();
        queryAllDoctorEducations();
    };

    return {
        register,
        handleSubmit,
        errors,
        watch,
        reset,
        setValue,
        showErrorModal,
        setShowErrorModal,
        closeErrorMessage,
        showSuccessModal,
        setShowSuccessModal,
        successBody,
        errorBody,
        setErrorBody,
        setSelectedEducation,
        onCloseSuccessMessage,
        saveEducation,
        isEditing: JSON.stringify(defaultValues) !== '{}',
        defaultValues,
        isLoading,
        resetState,
        isDirty
    };
}
