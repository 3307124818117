import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import createExperience from '../api/createExperience';
import updateExperience from '../api/updateExperience';
import { type } from '@testing-library/user-event/dist/type';

export default function useDoctorExperience(queryAllDoctorExperiences, selectedUser) {
    const [isLoading, setIsLoading] = useState(true);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState('');
    const [successBody, setSuccessBody] = useState('');
    const [defaultValues, setDefaultValues] = useState({});
    const [selectedExperience, setSelectedExperience] = useState(null);

    const {
        register,
        handleSubmit,
        formState: { errors, isDirty },
        watch,
        reset,
        setValue
    } = useForm(defaultValues);

    useEffect(() => {
        setIsLoading(true);

        if (selectedExperience) {
            setDefaultValues(selectedExperience);
            reset(selectedExperience);

            setValue('status', selectedExperience.status ? 'true' : 'false', { shouldValidate: true });
        }

        setIsLoading(false);
    }, [reset, selectedExperience, setValue]);

    const resetState = () => {
        setShowSuccessModal(false);
        setShowErrorModal(false);
        setErrorBody('');
        setSuccessBody('');
        setDefaultValues({});
        setSelectedExperience(null);
        reset({ experienceName: '', experienceDetail: '' });
        setValue('status', '', { shouldValidate: true });
    };

    const _createErrorMessage = (errorMessage) => {
        setShowErrorModal(true);
        setErrorBody(errorMessage);
    };

    const closeErrorMessage = () => {
        setShowErrorModal(false);
        setErrorBody('');
    };

    const _createSuccessMessage = (errorMessage) => {
        setShowSuccessModal(true);
        setSuccessBody(errorMessage);
    };

    const saveExperience = (data) => {
        if (!data) {
            _createErrorMessage('No se pudo obtener información válida para guardar. Inténtelo de nuevo.');
            return;
        }

        if (JSON.stringify(defaultValues) === '{}') {
            executeCreateExperience(data);
        } else {
            const adjustedData = { ...data, idExperience: data.id, status: data.status === 'true' };
            doUpdateExperience(adjustedData);
        }
    };

    const executeCreateExperience = async (newExperience) => {
        setIsLoading(true);
        const createNewExperience = await createExperience(selectedUser.id, newExperience, _createErrorMessage);

        if (createNewExperience) {
            _createSuccessMessage('¡Se ha guardado el registro con éxito!');
        }

        setIsLoading(false);
    };

    const doUpdateExperience = async (updatedExperienceObject) => {
        setIsLoading(true);
        const executeUpdatedExperience = await updateExperience(
            selectedUser.id,
            updatedExperienceObject,
            _createErrorMessage
        );

        if (executeUpdatedExperience) {
            _createSuccessMessage('¡Se ha actualizado el registro con éxito!');
        }

        setIsLoading(false);
    };

    const onCloseSuccessMessage = () => {
        resetState();
        queryAllDoctorExperiences();
    };

    return {
        register,
        handleSubmit,
        errors,
        watch,
        reset,
        setValue,
        showErrorModal,
        setShowErrorModal,
        closeErrorMessage,
        showSuccessModal,
        setShowSuccessModal,
        successBody,
        errorBody,
        setErrorBody,
        setSelectedExperience,
        onCloseSuccessMessage,
        saveExperience,
        isEditing: JSON.stringify(defaultValues) !== '{}',
        defaultValues,
        isLoading,
        resetState,
        isDirty
    };
}
