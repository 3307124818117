import getAds from '../api/getAds';
import { useEffect, useMemo, useState } from 'react';
import { useTable, usePagination, useSortBy, useFilters, useGlobalFilter } from 'react-table';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function useAds() {
    const [ads, setAds] = useState([]);
    const [errorMessage, setErrorMessage] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedAd, setSelectedAd] = useState(null);

    const queryAds = async () => {
        const result = await getAds(setErrorMessage);

        if (result) {
            setAds(result);
            setIsLoading(false);
            return;
        }

        setIsLoading(false);

        setErrorMessage('No se pudo completar la solicitud de citas. Inténtelo de nuevo.');
    };

    useEffect(() => {
        queryAds();
    }, []);

    const _handleClick = (event, selectedItem) => {
        event.preventDefault();
        setSelectedAd(selectedItem);
    };

    const _closeAdView = () => {
        setSelectedAd(null);
    };

    const onSuccessAdUpdate = () => {
        _closeAdView();
        queryAds();
    };

    const onNewAd = () => {
        setSelectedAd({});
    };

    const adsTable = useMemo(() => {
        return ads.map((ad) => {
            return {
                col1: ad.name,
                col2: ad.link,
                col3: new Date(ad.creation).toLocaleDateString(),
                col4: (
                    <span
                        className={classNames(
                            'inline-flex rounded-full px-2 text-xs font-semibold leading-5',
                            ad.status ? 'bg-green-100' : '',
                            !ad.status ? 'bg-red-100' : '',
                            ad.status ? 'text-green-800' : '',
                            !ad.status ? 'text-red-800' : ''
                        )}
                    >
                        {ad.status ? 'Activo' : 'Inactivo'}
                    </span>
                ),
                col5: (
                    <div
                        onClick={(e) => _handleClick(e, ad)}
                        className="text-dl-primary-900 hover:text-dl-accent cursor-pointer"
                    >
                        Editar
                        <span className="sr-only">, {ad.name}</span>
                    </div>
                )
            };
        });
    }, [ads]);

    const adsColumns = useMemo(() => {
        return [
            {
                Header: 'Nombre',
                accessor: 'col1' // accessor is the "key" in the data
            },
            {
                Header: 'Enlace',
                accessor: 'col2' // accessor is the "key" in the data
            },
            {
                Header: 'Fecha de creación',
                accessor: 'col3' // accessor is the "key" in the data
            },
            {
                Header: 'Estado',
                accessor: 'col4' // accessor is the "key" in the data
            },
            {
                Header: 'Acciones',
                accessor: 'col5' // accessor is the "key" in the data
            }
        ];
    }, []);

    const adsTableInstance = useTable(
        { columns: adsColumns, data: adsTable },
        useFilters, // useFilters!
        useGlobalFilter, // useGlobalFilter!,
        useSortBy,
        usePagination
    );

    return {
        ads,
        errorMessage,
        queryUsers: queryAds,
        usersTable: adsTable,
        isLoading,
        setIsLoading,
        adsColumns,
        adsTableInstance,
        _closeUserView: _closeAdView,
        selectedAd,
        onSuccessAdUpdate,
        onNewAd
    };
}
