import { useState } from 'react';
import WhiteCardContainer from '../../../../../components/containers/forms/WhiteCardContainer';
import FormFieldsContainer from '../../../../../components/containers/forms/FormFieldsContainer';
import FullRow from '../../../../../components/formElements/FullRow';
import PrimaryBadgeWithRemove from '../../../../../components/badges/PrimaryBadgeWithRemove';
import ControlledFormSelectDrop from '../../../../../components/formElements/controlled/ControlledFormSelectDrop';
import useProfileLanguages from './hooks/useProfileLanguages';
import CustomSuccessModal from '../../../../../components/alerts/customSuccessModal/CustomSuccessModal';
import CustomRedAlert from '../../../../../components/alerts/customRedAlert/CustomRedAlert';
import DeleteConfirmationModal from '../../../../../components/alerts/deleteConfirmationModal/DeleteConfirmationModal';

export default function ProfileLanguages(props) {
    const { basicProfile, formattedLanguages, languagesInProfile, queryLanguagesInProfile, selectedUser } = props;

    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const {
        executeSaveLanguageInProfile,
        showSuccessModal,
        successBody,
        onCloseSuccessMessage,
        showErrorModal,
        errorBody,
        closeErrorMessage,
        onDeleteLanguageInProfile
    } = useProfileLanguages(queryLanguagesInProfile, selectedUser);

    if (!basicProfile.hasProfile) {
        return null;
    }

    const _onConfirmDelete = (id) => {
        onDeleteLanguageInProfile(id);
        setOpenDeleteModal(false);
        queryLanguagesInProfile();
    };

    return (
        <WhiteCardContainer>
            <FormFieldsContainer>
                <FullRow>
                    <div className="min-h-[calc(100vh_-_506px)]">
                        <ControlledFormSelectDrop
                            label={'Idioma'}
                            identifier={'idioma'}
                            options={formattedLanguages}
                            value={''}
                            onChange={(e) => executeSaveLanguageInProfile(e.target.value)}
                        />

                        {languagesInProfile.length > 0 && (
                            <div className="mt-12">
                                {languagesInProfile.map((lang) => (
                                    <PrimaryBadgeWithRemove
                                        key={lang.id}
                                        type={'large'}
                                        label={lang.label}
                                        onRemove={() => {
                                            setOpenDeleteModal(lang.id);
                                        }}
                                    />
                                ))}
                            </div>
                        )}
                        {languagesInProfile.length === 0 && (
                            <div>
                                <p className="mt-1 text-sm text-gray-500">No ha seleccionado ningún lenguaje</p>
                            </div>
                        )}
                    </div>
                </FullRow>
                {showSuccessModal && (
                    <CustomSuccessModal
                        title={'Acción ejecutada con éxito!'}
                        bodyText={successBody}
                        buttonText={'Cerrar'}
                        buttonAction={onCloseSuccessMessage}
                        open={showSuccessModal}
                        onClose={onCloseSuccessMessage}
                    />
                )}

                {showErrorModal && (
                    <CustomRedAlert
                        open={showErrorModal}
                        title={`Ocurrió un error.`}
                        bodyText={errorBody}
                        onClose={closeErrorMessage}
                    />
                )}
                {openDeleteModal && (
                    <DeleteConfirmationModal
                        open={Boolean(openDeleteModal)}
                        onClose={() => setOpenDeleteModal(false)}
                        onCancel={() => setOpenDeleteModal(false)}
                        onConfirm={() => _onConfirmDelete(openDeleteModal)}
                        title={'¿Desea eliminar est lenguaje de su perfil?'}
                        bodyText={
                            'Esta acción es permanente. Si desea volver a agregarlo tendrá que seleccionar desde el menú.'
                        }
                    />
                )}
            </FormFieldsContainer>
        </WhiteCardContainer>
    );
}
