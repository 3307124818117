import { authenticatedPatch, authenticatedSubscriptionList } from '../../../../../../api/request';

export default async function updateProfileApproval(userId, saveData, setErrorMessage) {
    const onFail = () => setErrorMessage('Ocurrió un error guardando la actualización de la cita.');
    const url = `/doctor-profile-management/profile-approval/${userId}`;
    return await authenticatedPatch(url, onFail, saveData);
}


export async function getSubscriptionList() {
    const url = `/subscription/list`
    return await authenticatedSubscriptionList(url)
}