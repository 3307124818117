import axios from 'axios';

const authHeader = () => {
  const token = localStorage.getItem('adminToken');
  if (token) {
    return { Authorization: 'Bearer ' + token };
  }
  return {};
};

const Axios = axios.create({
  baseURL: process.env.REACT_APP_API_SERVER,
  headers: authHeader()
});

Axios.interceptors.request.use((config) => {
  config.headers = { ...config.headers, ...authHeader() };
  return config;
});

export default Axios