import usePasswordForm from './hooks/usePasswordForm';
import { CustomRedAlert, CustomSuccessModal, FormInputField, FullRow } from '../../components';
import { KeyIcon } from '@heroicons/react/24/outline';
import MainLogo from '../../assets/images/dl-logo.png';
import { useNavigate } from 'react-router-dom';

const ChangePassword = ({ performLogout }) => {
    const {
        register: registerPassword,
        handleSubmit: handlePasswordSubmit,
        errors: passwordErrors,
        validatePasswordConfirmation,
        // isLoading,
        showErrorModal: showErrorModalPassword,
        showSuccessModal: showSuccessModalPassword,
        onUpdatePassword,
        onCloseErrorModal: onCloseErrorModalPassword,
        onCloseSuccessModal: onCloseSuccessModalPassword
    } = usePasswordForm();

    const navigate = useNavigate();

    const executeLogout = () => {
        performLogout();
        navigate('/login');
    };

    const showError = showErrorModalPassword;
    const showSuccess = showSuccessModalPassword;
    const errorMsg = 'No se pudo cambiar de contraseña. revise la información ingresada e inténtelo de nuevo';
    const successMsg = 'Contraseña actualizada con éxito';

    const closeSuccess = () => {
        onCloseSuccessModalPassword();
        executeLogout();
    };

    const closeError = () => {
        onCloseErrorModalPassword();
    };

    return (
        <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
            <div className="w-full max-w-md space-y-8">
                <div>
                    <img className="mx-auto h-24 w-auto" src={MainLogo} alt="DocLinkLogo" />
                    <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-blue-900">
                        Cambia tu contraseña
                    </h2>
                    <p className="mt-2 text-center text-sm text-gray-600">Esto hará que cierres sesión</p>
                </div>
                <form onSubmit={handlePasswordSubmit(onUpdatePassword)}>
                    <div className="shadow sm:rounded-md sm:overflow-hidden">
                        <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                            <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                                <div>
                                    <h3 className="text-lg leading-6 font-medium text-dl-secondary">
                                        Actualizar contraseña
                                    </h3>
                                </div>
                                <div className="grid grid-cols-6 gap-6">
                                    <FullRow>
                                        <FormInputField
                                            identifier="oldPassowrd"
                                            label="Contraseña actual"
                                            iconComponent={
                                                <KeyIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                            }
                                            type="password"
                                            registration={registerPassword('oldPassword', {
                                                required: true
                                            })}
                                        />
                                        {passwordErrors.oldPassword?.type === 'required' ? (
                                            <p className="text-red-700 font-light">Inserte contraseña actual</p>
                                        ) : null}
                                    </FullRow>
                                    <FullRow>
                                        <FormInputField
                                            identifier="newPassword"
                                            label="Nueva contraseña"
                                            iconComponent={
                                                <KeyIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                            }
                                            type="password"
                                            registration={registerPassword('newPassword', {
                                                required: true
                                            })}
                                        />
                                        {passwordErrors.newPassword?.type === 'required' ? (
                                            <p className="text-red-700 font-light">Inserte nueva contraseña</p>
                                        ) : null}
                                    </FullRow>
                                    <FullRow>
                                        <FormInputField
                                            identifier="newPasswordConfirm"
                                            label="Confirmar nueva contraseña"
                                            iconComponent={
                                                <KeyIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                            }
                                            type="password"
                                            registration={registerPassword('newPasswordConf', {
                                                required: true,
                                                validate: validatePasswordConfirmation
                                            })}
                                        />
                                        {passwordErrors.newPasswordConf?.type === 'required' ? (
                                            <p className="text-red-700 font-light">Confirme contraseña</p>
                                        ) : null}
                                        {passwordErrors.newPasswordConf?.type === 'validate' ? (
                                            <p className="text-red-700 font-light">Las contraseñas deben coincidir</p>
                                        ) : null}
                                    </FullRow>
                                </div>
                            </div>
                        </div>
                        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                            <button
                                type="submit"
                                className="bg-dl-primary-900 border border-transparent rounded-full shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dl-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dl-primary-700"
                            >
                                Actualizar contraseña
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            {showError && (
                <CustomRedAlert open={showError} title="Ocurrió un error." bodyText={errorMsg} onClose={closeError} />
            )}
            {showSuccess && (
                <CustomSuccessModal
                    title={successMsg}
                    buttonText="OK"
                    buttonAction={closeSuccess}
                    open={showSuccess}
                    onClose={closeSuccess}
                />
            )}
        </div>
    );
};

export default ChangePassword;
