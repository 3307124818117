import WhiteCardContainer from '../../../../../components/containers/forms/WhiteCardContainer';
import ExperienceList from './components/experienceList/ExperienceList';
import FormFieldsContainer from '../../../../../components/containers/forms/FormFieldsContainer';
import ProfileFormHeader from '../../ProfileFormHeader/ProfileFormHeader';
import FormInputField from '../../../../../components/formElements/FormInputField';
import HalfRow from '../../../../../components/formElements/HalfRow';
import useDoctorExperience from './hooks/useDoctorExperience';
import FullRow from '../../../../../components/formElements/FullRow';
import FormTextArea from '../../../../../components/formElements/FormTextArea';
import FormButton from '../../../../../components/formElements/FormButton';
import FormFieldsFooter from '../../../../../components/containers/forms/FormFieldsFooter';
import FormSelectDrop from '../../../../../components/formElements/FormSelectDrop';
import CustomRedAlert from '../../../../../components/alerts/customRedAlert/CustomRedAlert';
import CustomSuccessModal from '../../../../../components/alerts/customSuccessModal/CustomSuccessModal';
import FormResetButton from '../../../../../components/formElements/FormResetButton';

const statusOptions = [
    { id: true, label: 'Activo' },
    { id: false, label: 'Inactivo' }
];

export default function DoctorExperience(props) {
    const { experiences, queryAllDoctorExperiences, selectedUser } = props;

    const {
        register,
        errors,
        handleSubmit,
        saveExperience,
        isEditing,
        defaultValues,
        showErrorModal,
        errorBody,
        closeErrorMessage,
        showSuccessModal,
        successBody,
        onCloseSuccessMessage,
        setSelectedExperience,
        resetState,
        isDirty
    } = useDoctorExperience(queryAllDoctorExperiences, selectedUser);

    return (
        <>
            <form action="#" method="POST" onSubmit={handleSubmit(saveExperience)}>
                <WhiteCardContainer>
                    <FormFieldsContainer>
                        <ProfileFormHeader
                            title={!isEditing ? 'Nueva Experiencia' : 'Actualizar Experiencia'}
                            initialText={
                                'Ingresa los datos que te interesa que el paciente conozca sobre tu experiencia.'
                            }
                        />
                        <div className="grid grid-cols-6 gap-6">
                            <HalfRow>
                                <FormInputField
                                    identifier={'experienceName'}
                                    label={'Título de la experiencia'}
                                    type={'text'}
                                    placeholder={'Ej. Emergencias en Hospital General (2007-2010)'}
                                    helperText={'Requerido'}
                                    registration={register('experienceName', { required: true })}
                                    errorMessage={errors.experienceName ? 'Este campo es requerido' : null}
                                />
                            </HalfRow>
                            <FullRow>
                                <FormTextArea
                                    label={'Detalle'}
                                    identifier={'experienceDetail'}
                                    rows={3}
                                    placeholder={
                                        'Ej. Experiencia en tratamientos intensivos durante todo mi tiempo en el hospital'
                                    }
                                    helperText={'Requerido'}
                                    registration={register('experienceDetail', { required: true })}
                                    errorMessage={errors.experienceDetail ? 'Este campo es requerido' : null}
                                />
                            </FullRow>
                            {isEditing && (
                                <HalfRow>
                                    <FormSelectDrop
                                        label={'Estado'}
                                        identifier={'status'}
                                        options={statusOptions}
                                        defaultValue={defaultValues.status}
                                        helperText={'Requerido'}
                                        registration={register('status', { required: true })}
                                        errorMessage={errors.status ? 'Este campo es requerido' : null}
                                    />
                                </HalfRow>
                            )}
                        </div>
                    </FormFieldsContainer>
                    <FormFieldsFooter>
                        <FormResetButton onClick={resetState} label={'Cancelar'} />
                        <FormButton
                            label={isEditing ? 'Actualizar' : 'Guardar'}
                            type={'submit'}
                            disabled={isEditing && !isDirty}
                        />
                    </FormFieldsFooter>
                </WhiteCardContainer>
                {showErrorModal && (
                    <CustomRedAlert
                        open={true}
                        title={'Ocurrió un error.'}
                        bodyText={errorBody}
                        onClose={closeErrorMessage}
                    />
                )}

                {showSuccessModal && (
                    <CustomSuccessModal
                        title={isEditing ? 'Perfil actualizado con éxito' : 'Perfil inicial guardado con éxito'}
                        bodyText={successBody}
                        buttonText={'Actualizar Perfil'}
                        buttonAction={onCloseSuccessMessage}
                        open={showSuccessModal}
                        onClose={onCloseSuccessMessage}
                    />
                )}
            </form>
            <div className="ml-1">
                <div className="ml-5">
                    <ProfileFormHeader title={'Experiencias existentes'} />
                </div>
                {experiences.length === 0 && <p>No hay experiencias para mostrar</p>}
                {experiences.length > 0 && <ExperienceList items={experiences} onClick={setSelectedExperience} />}
            </div>
        </>
    );
}
