import { Login } from '../pages/Login';
import './App.css';
import 'react-datepicker/dist/react-datepicker.min.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAuthentication } from '../api';
import { PrivateRoute } from '../components/PrivateRoute';
import { Layout } from '../layout';
import { ChangePassword } from '../pages/ChangePassword';

const App = () => {
  const { performLogout, checkIfLoggedIn, performLogin, userInfo, getUserType } = useAuthentication();

  const [isLoading, setIsLoading] = useState(true);
  const isAuth = getUserType();

  useEffect(() => {
    const aCall = async () => {
      await checkIfLoggedIn();
      setIsLoading(false);
    };

    aCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login performLogin={performLogin} />} />
        <Route path="/change-password" element={<ChangePassword performLogout={performLogout} />} />
        <Route
          path="*"
          element={
            <PrivateRoute isAuth={isAuth}>
              <Layout performLogout={performLogout} userInfo={userInfo} checkIfLoggedIn={checkIfLoggedIn} />
            </PrivateRoute>
          }
        />
        ;
      </Routes>
    </BrowserRouter>
  );
};

export default App;
