import WhiteCardContainer from '../../../../../components/containers/forms/WhiteCardContainer';
import EducationList from './components/educationList/EducationList';
import FormFieldsContainer from '../../../../../components/containers/forms/FormFieldsContainer';
import ProfileFormHeader from '../../ProfileFormHeader/ProfileFormHeader';
import FormInputField from '../../../../../components/formElements/FormInputField';
import HalfRow from '../../../../../components/formElements/HalfRow';
import useDoctorEducation from './hooks/useDoctorEducation';
import FullRow from '../../../../../components/formElements/FullRow';
import FormTextArea from '../../../../../components/formElements/FormTextArea';
import FormButton from '../../../../../components/formElements/FormButton';
import FormFieldsFooter from '../../../../../components/containers/forms/FormFieldsFooter';
import FormSelectDrop from '../../../../../components/formElements/FormSelectDrop';
import CustomRedAlert from '../../../../../components/alerts/customRedAlert/CustomRedAlert';
import CustomSuccessModal from '../../../../../components/alerts/customSuccessModal/CustomSuccessModal';
import FormResetButton from '../../../../../components/formElements/FormResetButton';

const statusOptions = [
    { id: true, label: 'Activo' },
    { id: false, label: 'Inactivo' }
];

export default function DoctorEducation(props) {
    const { education, queryAllDoctorEducation, selectedUser } = props;

    const {
        register,
        errors,
        handleSubmit,
        saveEducation,
        isEditing,
        defaultValues,
        showErrorModal,
        errorBody,
        closeErrorMessage,
        showSuccessModal,
        successBody,
        onCloseSuccessMessage,
        setSelectedEducation,
        resetState,
        isDirty
    } = useDoctorEducation(queryAllDoctorEducation, selectedUser);

    return (
        <>
            <form action="#" method="POST" onSubmit={handleSubmit(saveEducation)}>
                <WhiteCardContainer>
                    <FormFieldsContainer>
                        <ProfileFormHeader
                            title={!isEditing ? 'Agregar Educación' : 'Actualizar Educación'}
                            initialText={
                                'Ingresa los datos que te interesa que el paciente conozca sobre tu educación.'
                            }
                        />
                        <div className="grid grid-cols-6 gap-6">
                            <HalfRow>
                                <FormInputField
                                    identifier={'educationName'}
                                    label={'Título de la educación'}
                                    type={'text'}
                                    placeholder={'Ej. Universidad El Salvador - Doctor en Medicina General (1990-1998)'}
                                    helperText={'Requerido'}
                                    registration={register('educationName', { required: true })}
                                    errorMessage={errors.educationName ? 'Este campo es requerido' : null}
                                />
                            </HalfRow>
                            <FullRow>
                                <FormTextArea
                                    label={'Detalle'}
                                    identifier={'educationDetail'}
                                    rows={3}
                                    placeholder={
                                        'Ej. Educación principal sobre medicina, con un diplomado en enfermedades virales (obtenido en 1995)'
                                    }
                                    helperText={'Requerido'}
                                    registration={register('educationDetail', { required: true })}
                                    errorMessage={errors.educationDetail ? 'Este campo es requerido' : null}
                                />
                            </FullRow>
                            {isEditing && (
                                <HalfRow>
                                    <FormSelectDrop
                                        label={'Estado'}
                                        identifier={'status'}
                                        options={statusOptions}
                                        defaultValue={defaultValues.status}
                                        helperText={'Requerido'}
                                        registration={register('status', { required: true })}
                                        errorMessage={errors.status ? 'Este campo es requerido' : null}
                                    />
                                </HalfRow>
                            )}
                        </div>
                    </FormFieldsContainer>
                    <FormFieldsFooter>
                        <FormResetButton onClick={resetState} label={'Cancelar'} />
                        <FormButton
                            label={isEditing ? 'Actualizar' : 'Guardar'}
                            type={'submit'}
                            disabled={isEditing && !isDirty}
                        />
                    </FormFieldsFooter>
                </WhiteCardContainer>
                {showErrorModal && (
                    <CustomRedAlert
                        open={true}
                        title={'Ocurrió un error.'}
                        bodyText={errorBody}
                        onClose={closeErrorMessage}
                    />
                )}

                {showSuccessModal && (
                    <CustomSuccessModal
                        title={isEditing ? 'Registro actualizado con éxito' : 'Nuevo registro guardado con éxito'}
                        bodyText={successBody}
                        buttonText={'Aceptar'}
                        buttonAction={onCloseSuccessMessage}
                        open={showSuccessModal}
                        onClose={onCloseSuccessMessage}
                    />
                )}
            </form>
            <div className="ml-1">
                <div className="ml-5">
                    <ProfileFormHeader title={'Educaciones existentes'} />
                </div>
                {education.length === 0 && <p className="ml-4 mt-4">No hay entradas de educación para mostrar</p>}
                {education.length > 0 && <EducationList items={education} onClick={setSelectedEducation} />}
            </div>
        </>
    );
}
