import { WhiteCardContainer, FormFieldsContainer } from '../../components';
import useDoctorProfiles from './hooks/useDoctorProfiles';
import { SimpleDataTable } from './components/SimpleDataTable';
import { ProfileFormHeader } from './components/ProfileFormHeader';
import DoctorUserDetails from './components/DoctorUserDetails/DoctorUserDetails';
import DoctorOwnProfile from './components/doctorOwnProfile/DoctorOwnProfile';

const DoctorProfiles = ({ userInfo }) => {
  const { userTableInstance, isLoading, selectedUser, _closeUserView, queryUsers } = useDoctorProfiles();

  return (
    <WhiteCardContainer>
      {JSON.stringify(selectedUser) === '{}' && (
        <FormFieldsContainer>
          <ProfileFormHeader title="Perfiles de Doctores" />
        </FormFieldsContainer>
      )}
      {!isLoading && userTableInstance && JSON.stringify(selectedUser) === '{}' && <SimpleDataTable tableInstance={userTableInstance} />}

      {!isLoading && JSON.stringify(selectedUser) !== '{}' && <DoctorOwnProfile selectedUser={selectedUser} onClose={_closeUserView} userInfo={userInfo} queryUsers={queryUsers} />}
    </WhiteCardContainer>
  );
};

export default DoctorProfiles;
