import getAppointments from '../api/getAppointments';
import { useEffect, useMemo, useState } from 'react';
import { useTable, usePagination, useSortBy, useFilters, useGlobalFilter } from 'react-table';

const statusNames = {
    pending: 'Pendiente',
    completed: 'Completada',
    canceled_by_doctor: 'Cancelada por doctor',
    canceled_by_user: 'Cancelada por usuario'
};

export default function useAppointmentHistory() {
    const now = new Date();
    now.setHours(0, 0, 0);
    const [appointments, setAppointments] = useState([]);
    const [errorMessage, setErrorMessage] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [selectionMode, setSelectionMode] = useState('viewOnly');
    const [selectedAppointment, setSelectedAppointment] = useState({});
    const [endDate, setEndDate] = useState(new Date(now));
    const [startDate, setStartDate] = useState(new Date(now.setDate(now.getDate() - 30)));


    const queryAppointmentHistory = async () => {

        const getHistory =
            await getAppointments(
                startDate.toJSON().slice(0, 10),
                endDate.toJSON().slice(0, 10),
                setErrorMessage
            );

        if (getHistory) {
            setAppointments(getHistory);
            setIsLoading(false);
            return;
        }

        setIsLoading(false);

        setErrorMessage('No se pudo completar la solicitud de citas. Inténtelo de nuevo.');
    };

    useEffect(() => {
        if (startDate < endDate) {
            queryAppointmentHistory();
        }
    }, [startDate, endDate]);

    const _handleClick = (event, selectedItem, mode) => {
        event.preventDefault();
        setSelectionMode(mode);
        setSelectedAppointment(selectedItem);
    };

    const _closeAppointmentView = () => {
        setSelectedAppointment({});
        setSelectionMode(null);
    };

    const _handleSuccessfulEvent = () => {
        queryAppointmentHistory();
        _closeAppointmentView();
    };

    const appointmentsTable = useMemo(() => {
        return appointments.map((appointment) => {
            return {
                col1: appointment.userFullName,
                col2: appointment.userEmail,
                col3: appointment.motiveName,
                col4: appointment.appDate,
                col5: appointment.appTime,
                col6: statusNames[appointment.statusName],
                col7: (<div className='flex flex-row gap-4'>
                    <a
                        href="#"
                        onClick={(e) => _handleClick(e, appointment, 'viewOnly')}
                        className="text-dl-primary-900 hover:text-dl-accent"
                    >
                        Ver Detalles
                        <span className="sr-only">, {appointment.userFullName}</span>
                    </a>
                    {
                        appointment.statusName === 'pending' && (<>
                            <a
                                href="#"
                                onClick={(e) => _handleClick(e, appointment, 'complete')}
                                className="text-dl-primary-900 hover:text-dl-accent"
                            >
                                Completar Cita
                                <span className="sr-only">, {appointment.userFullName}</span>
                            </a>
                            <a
                                href="#"
                                onClick={(e) => _handleClick(e, appointment, 'cancel')}
                                className="text-dl-primary-900 hover:text-dl-accent"
                            >
                                Cancelar Cita
                                <span className="sr-only">, {appointment.userFullName}</span>
                            </a> </>)
                    }
                </div>)
            };
        });
    }, [appointments]);


    const appointmentColumns = useMemo(() => {
        return [
            {
                Header: 'Nombre',
                accessor: 'col1' // accessor is the "key" in the data
            },
            {
                Header: 'Correo',
                accessor: 'col2' // accessor is the "key" in the data
            },
            {
                Header: 'Motivo',
                accessor: 'col3' // accessor is the "key" in the data
            },
            {
                Header: 'Fecha',
                accessor: 'col4' // accessor is the "key" in the data
            },
            {
                Header: 'Hora',
                accessor: 'col5' // accessor is the "key" in the data
            },
            {
                Header: 'Estado',
                accessor: 'col6' // accessor is the "key" in the data
            },
            {
                Header: 'Acciones',
                accessor: 'col7' // accessor is the "key" in the data
            }
        ];
    }, []);

    const appointmentsTableInstance = useTable(
        { columns: appointmentColumns, data: appointmentsTable },
        useFilters, // useFilters!
        useGlobalFilter, // useGlobalFilter!,
        useSortBy,
        usePagination
    );

    return {
        appointments,
        errorMessage,
        queryAppointmentHistory,
        appointmentsTable,
        isLoading,
        setIsLoading,
        appointmentColumns,
        appointmentsTableInstance,
        selectionMode,
        setSelectionMode,
        _closeAppointmentView,
        selectedAppointment,
        setStartDate,
        setEndDate,
        startDate,
        endDate,
        _handleSuccessfulEvent
    };
}
