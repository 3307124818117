export default function PrimaryBadgeWithRemove(props) {
    const { type, label, onRemove } = props;

    return (
        <>
            {type === 'small' && (
                <span
                    key={label}
                    className="inline-flex items-center rounded-full bg-dl-primary-500 py-0.5 pl-2 pr-0.5 text-xs font-medium text-dl-accent mx-10"
                >
                    {label}
                    <button
                        type="button"
                        onClick={onRemove}
                        className="ml-0.5 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 focus:bg-indigo-500 focus:text-white focus:outline-none"
                    >
                        <span className="sr-only">{`Remover ${label}`}</span>
                        <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                            <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                        </svg>
                    </button>
                </span>
            )}
            {type === 'large' && (
                <span
                    key={label}
                    className="inline-flex items-center rounded-full bg-dl-primary-500 py-0.5 pl-2.5 pr-1 text-sm font-medium text-dl-accent mx-1"
                >
                    {label}
                    <button
                        type="button"
                        onClick={onRemove}
                        className="ml-0.5 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 focus:bg-indigo-500 focus:text-white focus:outline-none"
                    >
                        <span className="sr-only">{`Remover ${label}`}</span>
                        <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                            <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                        </svg>
                    </button>
                </span>
            )}
        </>
    );
}
