import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import saveUpdatedAd from '../api/saveUpdatedAd';
import saveNewAd from '../api/saveNewAd';
import { format, parseISO } from 'date-fns';

export default function useAdsDetails(selectedAd, onSuccessfulSave) {
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState('');
    const [successBody, setSuccessBody] = useState('');
    const [isEditing, setIsEditing] = useState(null);

    const {
        register,
        handleSubmit,
        formState: { errors, isDirty },
        watch,
        // control,
        reset,
        setValue
    } = useForm();

    useEffect(() => {
        if (JSON.stringify(selectedAd) !== '{}') {
            setIsEditing(true);
            reset({ ...selectedAd, creation: format(parseISO(selectedAd.creation), 'yyyy-MM-dd H:mm:ss') });
            setValue('status', selectedAd.status.toString());
        } else {
            setIsEditing(false);
        }
    }, [selectedAd, reset]);

    const _createErrorMessage = (errorMessage) => {
        setShowErrorModal(true);
        setErrorBody(errorMessage);
    };

    const closeErrorMessage = () => {
        setShowErrorModal(false);
        setErrorBody('');
    };

    const _createSuccessMessage = (successMessage) => {
        setShowSuccessModal(true);
        setSuccessBody(successMessage);
    };

    const onCloseSuccessMessage = () => {
        setShowSuccessModal(false);
        setSuccessBody('');
        onSuccessfulSave();
    };

    const onSaveComplete = async (data) => {
        const executeSaveComplete = await saveNewAd(
            {
                ...data,
                status: data.status === 'true'
            },
            _createErrorMessage
        );

        if (executeSaveComplete) {
            _createSuccessMessage('¡Se ha actualizado el registro con éxito!');
        }
    };

    const onUpdate = async (data) => {
        const executeSaveComplete = await saveUpdatedAd(
            {
                ...data,
                idAds: data.id,
                status: data.status === 'true'
            },
            _createErrorMessage
        );

        if (executeSaveComplete) {
            _createSuccessMessage('¡Se ha actualizado el registro con éxito!');
        }
    };

    const onSaveSubmit = (data) => {
        if (!isEditing) {
            onSaveComplete(data);
        } else {
            onUpdate(data);
        }
        return;
    };

    return {
        register,
        handleSubmit,
        errors,
        watch,
        reset,
        setValue,
        isDirty,
        closeErrorMessage,
        onCloseSuccessMessage,
        showSuccessModal,
        showErrorModal,
        errorBody,
        successBody,
        onSaveComplete,
        onSaveSubmit,
        isEditing
    };
}
