const DoctorProfileInitialFormBO = {
    createBaseInitialForm() {
        return {
            patientContactPhone1: '',
            patientContactPhone2: '',
            consultationCost: '',
            examCost: '',
            paymentMethods: [
                { identifier: 'cash', checked: false, label: 'Efectivo' },
                { identifier: 'creditCard', checked: false, label: 'Tarjeta de Crédito' }
            ],
            governmentPermitInfo: '',
            address: '',
            latitudeAndLongitude: '',
            generalInfo: '',
            insurance: '',
            idCity: '',
            idSpecialty: ''
        };
    },
    translateBasicProfile(basicForm) {
        const cashChecked = basicForm.paymentMethods && basicForm.paymentMethods.includes('Efectivo');
        const creditCardChecked = basicForm.paymentMethods && basicForm.paymentMethods.includes('Tarjeta de Crédito');
        const latitudeAndLongitude =
            basicForm.latitude && basicForm.longitude ? `${basicForm.latitude}, ${basicForm.longitude}` : '';

        return {
            patientContactPhone1: basicForm.patientContactPhone1,
            patientContactPhone2: basicForm.patientContactPhone2 || '',
            consultationCost: basicForm.consultationCost,
            examCost: basicForm.examCost || '',
            cash: cashChecked,
            creditCard: creditCardChecked,
            governmentPermitInfo: basicForm.governmentPermitInfo || '',
            address: basicForm.address,
            latitudeAndLongitude,
            generalInfo: basicForm.generalInfo || '',
            insurance: basicForm.insurance || '',
            idCity: basicForm.idCity || '',
            idSpecialty: basicForm.idSpecialty || ''
        };
    },
    insertInObject(key, value, editableObject) {
        const newObject = { ...editableObject };
        newObject[key] = value;
        return newObject;
    },
    prepareSaveObject(basicForm) {
        //check for validity
        let paymentMethodString = '';

        if (basicForm.cash && basicForm.creditCard) {
            paymentMethodString = 'Efectivo, Tarjeta de Crédito';
        } else if (basicForm.cash && !basicForm.creditCard) {
            paymentMethodString = 'Efectivo';
        } else if (!basicForm.cash && basicForm.creditCard) {
            paymentMethodString = 'Tarjeta de Crédito';
        } else {
            return false;
        }

        let [latitude, longitude] = basicForm.latitudeAndLongitude.split(',');

        if (!latitude || latitude === '') {
            latitude = null;
        }

        if (!longitude || longitude === '') {
            longitude = null;
        }

        let idCity = parseInt(basicForm.idCity);
        let idSpecialty = parseInt(basicForm.idSpecialty);

        if (!idCity || idCity < 1) {
            return false;
        }

        if (!idSpecialty || idSpecialty < 1) {
            return false;
        }

        return {
            patientContactPhone1: basicForm.patientContactPhone1,
            ...(basicForm.patientContactPhone2 && { patientContactPhone2: basicForm.patientContactPhone2 }),
            consultationCost: basicForm.consultationCost,
            ...(basicForm.examCost && { examCost: basicForm.examCost }),
            paymentMethods: paymentMethodString,
            ...(basicForm.governmentPermitInfo && { governmentPermitInfo: basicForm.governmentPermitInfo }),
            ...(basicForm.generalInfo && { generalInfo: basicForm.generalInfo }),
            ...(basicForm.insurance && { insurance: basicForm.insurance }),
            address: basicForm.address,
            ...(latitude && { latitude: latitude.trim() }),
            ...(longitude && { longitude: longitude.trim() }),
            idCity,
            idSpecialty
        };
    }
};

export default DoctorProfileInitialFormBO;
