import { useLocation, Navigate } from "react-router-dom";

const PrivateRoute = ({ children, isAuth }) => {
  let location = useLocation();

  if (!isAuth) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  } else {
    return children;
  }
};

export default PrivateRoute;