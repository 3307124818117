import { useState } from 'react';
import { ProfileFormHeader } from '../ProfileFormHeader';
import HalfRow from '../../../../components/formElements/HalfRow';
import FormInputField from '../../../../components/formElements/FormInputField';
import FullRow from '../../../../components/formElements/FullRow';
import useUserDetails from './hooks/useUserDetails';
import CustomRedAlert from '../../../../components/alerts/customRedAlert/CustomRedAlert';
import CustomSuccessModal from '../../../../components/alerts/customSuccessModal/CustomSuccessModal';
import { CalendarIcon, PhoneIcon, UsersIcon, EnvelopeIcon } from '@heroicons/react/24/outline';
import FormSelectDrop from '../../../../components/formElements/FormSelectDrop';

export default function DoctorUserDetails(props) {
    const { selectedUser, onCancel, selectionMode, onSuccessfulSave, userStatusCatalog, documentTypes } = props;
    console.log(documentTypes, 'documentTypes');
    const [openReturnAlert, setOpenReturnAlert] = useState(false);

    const {
        handleSubmit,
        register,
        errors,
        isDirty,
        closeErrorMessage,
        onCloseSuccessMessage,
        showSuccessModal,
        showErrorModal,
        errorBody,
        successBody,
        onSaveSubmit,
        documentTypesOptions,
        userStatusOptions
    } = useUserDetails(selectedUser, onSuccessfulSave, selectionMode, userStatusCatalog, documentTypes);

    console.log(documentTypesOptions, 'documentTypesOptions');

    if (!selectionMode) {
        return null;
    }

    const _onReturnToUsers = () => {
        if (isDirty) {
            setOpenReturnAlert(true);
        } else {
            onCancel();
        }
    };

    const _onConfirmCancelAppointment = () => {
        setOpenReturnAlert(false);
        onCancel();
    };

    const _onCancelConfirmationForClosing = () => {
        setOpenReturnAlert(false);
    };

    return (
        <form onSubmit={handleSubmit(onSaveSubmit)}>
            <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                    {selectionMode !== 'viewOnly' && <ProfileFormHeader title={'Editar datos de doctor'} />}
                    <div>
                        <h3 className="text-lg leading-6 font-medium text-dl-secondary">Detalles del doctor</h3>
                    </div>
                    <div className="grid grid-cols-6 gap-6">
                        <HalfRow>
                            <FormInputField
                                identifier="firstName"
                                label="Nombre del Doctor"
                                iconComponent={<UsersIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                                type="string"
                                registration={register('firstName', {
                                    required: true
                                })}
                                disabled={selectionMode === 'viewOnly'}
                            />
                        </HalfRow>
                        <HalfRow>
                            <FormInputField
                                identifier="lastName"
                                label="Apellido del Doctor"
                                iconComponent={<UsersIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                                type="string"
                                registration={register('lastName', {
                                    required: true
                                })}
                                disabled={selectionMode === 'viewOnly'}
                            />
                        </HalfRow>
                        <HalfRow>
                            <FormInputField
                                identifier="birthDate"
                                label="Fecha de cumpleaños"
                                iconComponent={<CalendarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                                type="string"
                                registration={register('birthDate', {
                                    required: true
                                })}
                                disabled={selectionMode === 'viewOnly'}
                            />
                        </HalfRow>
                        <HalfRow>
                            <FormInputField
                                identifier="phone"
                                label="Número de teléfono de contacto"
                                iconComponent={<PhoneIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                                type="string"
                                registration={register('phone', {
                                    required: true
                                })}
                                disabled={selectionMode === 'viewOnly'}
                            />
                        </HalfRow>
                        <FullRow>
                            <FormInputField
                                identifier="email"
                                label="Dirección de email"
                                iconComponent={<EnvelopeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                                type="string"
                                registration={register('email', {
                                    required: true
                                })}
                                disabled
                            />
                        </FullRow>
                        <HalfRow>
                            <FormSelectDrop
                                label={'Tipo de Documento'}
                                identifier={'idDocumentTypes'}
                                options={documentTypesOptions}
                                defaultValue={selectedUser.idDocumentTypes}
                                defaultOption={false}
                                helperText={'Requerido'}
                                registration={register('idDocumentTypes', { required: true })}
                                errorMessage={errors.idSchedule ? 'Este campo es requerido' : null}
                            />
                        </HalfRow>
                        <HalfRow>
                            <FormInputField
                                identifier="personalDocument"
                                label="Número de documento"
                                type="string"
                                registration={register('personalDocument', {
                                    required: true
                                })}
                                disabled={selectionMode === 'viewOnly'}
                            />
                        </HalfRow>
                        <HalfRow>
                            <FormInputField
                                identifier="creation"
                                label="Creación"
                                type="string"
                                registration={register('creation', {
                                    required: true
                                })}
                                disabled
                            />
                        </HalfRow>
                        <HalfRow>
                            <FormSelectDrop
                                label={'Estado del usuario'}
                                identifier={'idDoctorUserStatus'}
                                options={userStatusOptions}
                                defaultValue={selectedUser.idDoctorUserStatus}
                                defaultOption={false}
                                helperText={'Requerido'}
                                registration={register('idDoctorUserStatus', { required: true })}
                                errorMessage={errors.idDoctorUserStatus ? 'Este campo es requerido' : null}
                            />
                        </HalfRow>
                    </div>
                </div>
                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                    {/*<button
                        type="button"
                        onClick={onCancel}
                        className="bg-dl-red border border-transparent rounded-full shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dl-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dl-red"
                    >
                        Cerrar
                    </button>*/}
                    <button
                        type="button"
                        onClick={_onReturnToUsers}
                        className="mr-2 rounded-full border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-dl-accent focus:ring-offset-2"
                    >
                        Regresar
                    </button>
                    {selectionMode === 'edit' && (
                        <button
                            type="submit"
                            disabled={!isDirty}
                            className="disabled:bg-dl-primary-700 bg-dl-primary-900 border border-transparent rounded-full shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dl-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dl-primary-700"
                        >
                            Actualizar Datos
                        </button>
                    )}
                </div>
            </div>

            {openReturnAlert && (
                <CustomRedAlert
                    open={openReturnAlert}
                    title={'¿Desea descartar los cambios?'}
                    bodyText={'La cita ha sido modificada, por favor confirme que desea salir de esta vista.'}
                    onClose={_onCancelConfirmationForClosing}
                    mainActionText={'Salir'}
                    onMainAction={_onConfirmCancelAppointment}
                    secondaryActionText={'Cancelar'}
                    onSecondaryAction={_onCancelConfirmationForClosing}
                />
            )}

            {showErrorModal && (
                <CustomRedAlert
                    open={showErrorModal}
                    title={'Ocurrió un error.'}
                    bodyText={errorBody}
                    onClose={closeErrorMessage}
                />
            )}

            {showSuccessModal && (
                <CustomSuccessModal
                    title={'Registro actualizado con éxito'}
                    bodyText={successBody}
                    buttonText={'Aceptar'}
                    buttonAction={onCloseSuccessMessage}
                    open={showSuccessModal}
                    onClose={onCloseSuccessMessage}
                />
            )}
        </form>
    );
}
