import getDoctorUsers from '../api/getDoctorUsers';
import getDoctorUserStatusCatalog from '../api/getDoctorUserStatusCatalog';
import { useEffect, useMemo, useState } from 'react';
import { useTable, usePagination, useSortBy, useFilters, useGlobalFilter } from 'react-table';
import { getDocumentTypes } from '../../../api/documentTypes';
import { InfoService } from '../../../services/DoctorProfile';
import { Switch } from '@headlessui/react';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function useDoctorProfiles() {
    const [users, setUsers] = useState([]);
    const [userStatusCatalog, setUserStatusCatalog] = useState([]);
    const [documentTypes, setDocumentTypes] = useState([]);
    const [errorMessage, setErrorMessage] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [selectionMode, setSelectionMode] = useState(null);
    const [selectedUser, setSelectedUser] = useState({});
    // const [refresh,setRefresh] = useState(true)

    const queryUsers = async () => {
        const result = await getDoctorUsers(setErrorMessage);

        if (result) {
            setUsers(result);
            setIsLoading(false);
            return;
        }

        setIsLoading(false);

        setErrorMessage('No se pudo completar la solicitud de citas. Inténtelo de nuevo.');
    };

    const queryUserStatusCatalog = async () => {
        const result = await getDoctorUserStatusCatalog(setErrorMessage);

        if (result) {
            setUserStatusCatalog(result);
            return;
        }

        setErrorMessage('No se pudo completar la solicitud de estado de usuario. Inténtelo de nuevo.');
    };

    const queryDocumentTypes = async () => {
        const result = await getDocumentTypes(setErrorMessage);

        if (result) {
            setDocumentTypes(result);
            return;
        }

        setErrorMessage('No se pudo completar la solicitud de tipos de documento. Inténtelo de nuevo.');
    };

    useEffect(() => {
        queryUsers();
        queryUserStatusCatalog();
        queryDocumentTypes();
    }, []);

    const _handleClick = (event, selectedItem) => {
        event.preventDefault();
        setSelectedUser(selectedItem);
    };

    const _closeUserView = () => {
        setSelectedUser({});
        setSelectionMode(null);
    };

    const onSuccessUserUpdate = () => {
        _closeUserView();
        queryUsers();
    };

    const handleRecommendation = (data) => {
      InfoService.recommended({ idDoctorUser: data.id, isRecommended: !data.isRecommended })
        .then(() => {setIsLoading(true);queryUsers()})
        .catch((err) => console.log(err));
    };

    const usersTable = useMemo(() => {
        return users.map((user) => {
            const fullName = `${user.firstName ?? ''} ${user.lastName ?? ''}`;
            return {
              col1: fullName,
              col2: user.email,
              col3: new Date(user.creation).toLocaleDateString(),
              col4: (
                <span
                  className={classNames(
                    'inline-flex rounded-full px-2 text-xs font-semibold leading-5',
                    user.profileApproval ? 'bg-green-100' : '',
                    !user.profileApproval ? 'bg-red-100' : '',
                    user.profileApproval ? 'text-green-800' : '',
                    !user.profileApproval ? 'text-red-800' : ''
                  )}
                >
                  {user.profileApproval ? 'Activo' : 'Incompleto'}
                </span>
              ),
              col5: (
                <div onClick={(e) => _handleClick(e, user)} className="text-dl-primary-900 hover:text-dl-accent cursor-pointer">
                  Ver Perfil
                  <span className="sr-only">, {fullName}</span>
                </div>
              ),
              col6: (
                <Switch
                  checked={user?.isRecommended}
                  onChange={() => handleRecommendation(user)}
                  className={`${user?.isRecommended ? 'bg-green-100' : 'bg-red-200'} relative inline-flex h-6 w-11 items-center rounded-full`}
                >
                  <span className="sr-only">Enable notifications</span>
                  <span className={`${user?.isRecommended ? 'translate-x-6' : 'translate-x-1'} inline-block h-4 w-4 transform rounded-full bg-white transition`} />
                </Switch>
              )
            };
        });
    }, [users]);

    const userColumns = useMemo(() => {
        return [
            {
                Header: 'Nombre',
                accessor: 'col1' // accessor is the "key" in the data
            },
            {
                Header: 'Correo electrónico',
                accessor: 'col2' // accessor is the "key" in the data
            },
            {
                Header: 'Fecha de creación',
                accessor: 'col3' // accessor is the "key" in the data
            },
            {
                Header: 'Estado Perfil',
                accessor: 'col4' // accessor is the "key" in the data
            },
            {
                Header: 'Acciones',
                accessor: 'col5' // accessor is the "key" in the data
            },
            {
                Header : "Recomendado",
                accessor : "col6"
            }
        ];
    }, []);

    const userTableInstance = useTable(
        { columns: userColumns, data: usersTable },
        useFilters, // useFilters!
        useGlobalFilter, // useGlobalFilter!,
        useSortBy,
        usePagination
    );

    return {
        users,
        errorMessage,
        queryUsers,
        usersTable,
        isLoading,
        setIsLoading,
        userColumns,
        userTableInstance,
        selectionMode,
        setSelectionMode,
        _closeUserView,
        selectedUser,
        userStatusCatalog,
        documentTypes,
        onSuccessUserUpdate
    };
}
