import { useEffect, useMemo, useState, Fragment } from 'react';
import { Speciality } from '../../services/Speciality';
import { Dialog, Transition } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { HalfRow } from '../../components';
import FormButton from '../../components/formElements/FormButton';
import { FormFieldsContainer, WhiteCardContainer } from '../../components';
import { useTable, usePagination, useSortBy, useFilters, useGlobalFilter, useAsyncDebounce } from 'react-table';
import DeleteConfirmationModal from '../../components/alerts/deleteConfirmationModal/DeleteConfirmationModal';
import Loader from '../../components/Loader/Loader';

const Specialities = () => {
  const [list, setList] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [dialog, setDialog] = useState({ isOpen: false, mode: '' });
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [id, selectedId] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  useEffect(() => {
    setIsLoading(true);
    Speciality.getList()
      .then((res) => setList(res.data))
      .then(() => setIsLoading(false))
      .catch((error) => console.error(error));
  }, [refresh]);

  const _handleClick = (selectedItem, mode) => {
    setName(selectedItem.name);
    setDescription(selectedItem.description);
    selectedId(selectedItem.id);
    openModal(mode);
  };

  const openModal = (mode) => {
    setDialog({ isOpen: true, mode });
  };

  const closeModal = () => {
    setDialog({ isOpen: false, mode: '' });
    setName('');
  };

  const handleSubmit = async () => {
    if (name.trim() && description.trim()) {
      if (dialog.mode === 'add') await Speciality.add({ name: name, description: description });
      if (dialog.mode === 'edit') await Speciality.update({ name, description: description, id });
      setRefresh(!refresh);
      closeModal();
    }
  };

  const _onConfirmDelete = (id) => {
    Speciality.delete(id).then(() => setRefresh(!refresh));
    setOpenDeleteModal(false);
    // setRefresh(!refresh);
  };

  let specialityTable = useMemo(() => {
    return list.map((item) => {
      return {
        col1: item.name,
        col2: item.description,
        col3: new Date(item.creation).toLocaleDateString(),
        col4: (
          <div className="flex gap-2">
            <div onClick={() => _handleClick(item, 'edit')} className="text-dl-primary-900 hover:text-dl-accent cursor-pointer">
              Editar
              <span className="sr-only"></span>
            </div>
            <div onClick={() => setOpenDeleteModal(item.id)} className="text-dl-primary-900 hover:text-dl-accent cursor-pointer">
              Borrar
              <span className="sr-only"></span>
            </div>
          </div>
        )
      };
    });
  }, [list]);

  const specialitycolumns = useMemo(() => {
    return [
      { Header: 'Nombre', accessor: 'col1' },
      { Header: 'Descripción', accessor: 'col2' },
      { Header: 'Fecha de creación', accessor: 'col3' },
      { Header: 'Acciones', accessor: 'col4' }
    ];
  }, []);

  const tableInstance = useTable({ columns: specialitycolumns, data: specialityTable }, useFilters, useGlobalFilter, useSortBy, usePagination);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    data,
    columns,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    nextPage,
    previousPage,
    setPageSize
  } = tableInstance;

  const [value, setValue] = useState(state.globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <WhiteCardContainer>
      <div className="px-4 sm:px-6 lg:px-8 mb-10">
        <div className="flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="grid grid-cols-6 gap-6 my-4">
                <HalfRow>
                  <label htmlFor={'search'} className="block text-sm font-medium text-dl-accent">
                    Buscador
                  </label>
                  <div className="relative mt-1 rounded-md shadow-sm">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <MagnifyingGlassIcon className="h-5 w-5" />
                    </div>
                    <input
                      type="text"
                      name={'search'}
                      id={'search'}
                      className={`mt-1 block w-full border border-gray-300 rounded-full shadow-sm py-2 px-3 focus:outline-none focus:ring-dl-primary-700 focus:border-dl-primary-700 sm:text-sm pl-10`}
                      placeholder={`Escriba para buscar en registros`}
                      value={value || ''}
                      onChange={(e) => {
                        setValue(e.target.value);
                        onChange(e.target.value);
                      }}
                    />
                  </div>
                </HalfRow>
                <HalfRow>
                  <FormFieldsContainer>
                    {/* <ProfileFormHeader title="City" /> */}
                    <div className="flex-row-reverse">
                      <FormButton type="button" label="Añadir Especialidad" onClick={() => openModal('add')} />
                    </div>
                  </FormFieldsContainer>
                </HalfRow>
              </div>
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table {...getTableProps()} className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th {...column.getHeaderProps(column.getSortByToggleProps())} scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                            <div className="inline-flex items-center">
                              {column.render('Header')}
                              <span>{column.isSorted ? column.isSortedDesc ? <ChevronUpIcon className="h-5 w-5" /> : <ChevronDownIcon className="h-5 w-5" /> : ''}</span>
                            </div>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white" {...getTableBodyProps()}>
                    {page.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <td {...cell.getCellProps()} className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {cell.render('Cell')}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <nav className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6" aria-label="Pagination">
                  <div className="hidden sm:block">
                    {!columns.some((col) => col.isSorted) && !state.globalFilter && page[0] ? (
                      <p className="text-sm text-gray-700">
                        Mostrando desde <span className="font-medium">{page[0].index + 1}</span> hasta <span className="font-medium">{page[page.length - 1].index + 1}</span> de un total de{' '}
                        <span className="font-medium">{data.length}</span> resultados
                      </p>
                    ) : (
                      <p className="text-sm text-gray-700">
                        Página <span className="font-medium">{state.pageIndex + 1}</span> de <span className="font-medium">{pageCount}</span>
                      </p>
                    )}
                  </div>
                  <div className="flex flex-1 justify-between sm:justify-end">
                    <select
                      id="displayOptions"
                      onChange={(e) => {
                        setPageSize(Number(e.target.value));
                      }}
                      className="mr-4 relative inline-flex items-center rounded-md mx-1 w-32 border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50"
                    >
                      <option value="10">Mostrar 10</option>
                      <option value="20">Mostrar 20</option>
                      <option value="50">Mostrar 50</option>
                    </select>
                    <button
                      type="button"
                      onClick={() => previousPage()}
                      disabled={!canPreviousPage}
                      className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50"
                    >
                      Anterior
                    </button>
                    <button
                      type="button"
                      onClick={() => nextPage()}
                      disabled={!canNextPage}
                      className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50"
                    >
                      Siguiente
                    </button>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      {dialog.isOpen && (
        <Transition appear show={dialog.isOpen} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={closeModal}>
            <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                      {dialog.mode === 'add' && 'Añadir especialidad'}
                      {dialog.mode === 'edit' && 'Editar especialidad'}
                    </Dialog.Title>
                    <div className="mt-2">
                      <div className='mb-4 items-center grid gap-4 grid-cols-3'>
                        <label>
                          Nombre  :
                        </label>
                        <input className='col-span-2' type="text" value={name} onChange={(e) => setName(e.target.value)} />
                      </div>
                      <div className='mb-4 items-center grid gap-4 grid-cols-3'>
                        <label>
                          Descripción  :
                        </label>
                        <textarea className='col-span-2' type="text" value={description} onChange={(e) => setDescription(e.target.value)} />
                      </div>
                      <div className="mt-4">
                        <FormButton label={dialog.mode === 'add' ? 'Agregar' : 'Actualizar'} type="button" onClick={() => handleSubmit('edit')} />
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      )}
      {openDeleteModal && (
        <DeleteConfirmationModal
          open={Boolean(openDeleteModal)}
          onClose={() => setOpenDeleteModal(false)}
          onCancel={() => setOpenDeleteModal(false)}
          onConfirm={() => _onConfirmDelete(openDeleteModal)}
          title={'¿Quieres eliminar la especialización?'}
          bodyText={'Esta acción es permanente.'}
        />
      )}
    </WhiteCardContainer>
  );
};

export default Specialities;
