import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import DoctorProfileInitialFormBO from '../businessObjects/DoctorProfileInitialFormBO';
import createBasicProfile from '../api/createBasicProfile';
import updateBasicProfile from '../api/updateBasicProfile';

export default function useInitialForm(basicProfile, cities, specialities, queryBasicProfile, selectedUser) {
    const [isLoading, setIsLoading] = useState(true);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState('');
    const [successBody, setSuccessBody] = useState('');
    const [defaultValues, setDefaultValues] = useState({});

    const formattedCities = useMemo(() => {
        return cities
            ? cities.map((city) => {
                  return { ...city, label: city.name };
              })
            : [];
    }, [cities]);

    const formattedSpecialities = useMemo(() => {
        return specialities
            ? specialities.map((speciality) => {
                  return { ...speciality, label: speciality.name };
              })
            : [];
    }, [specialities]);

    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
        reset,
        setValue
    } = useForm(defaultValues);

    useEffect(() => {
        if (!basicProfile || formattedSpecialities.length === 0 || formattedCities.length === 0) {
            return;
        }
        setIsLoading(true);

        if (basicProfile.hasProfile) {
            const translatedProfile = DoctorProfileInitialFormBO.translateBasicProfile(basicProfile);
            setDefaultValues(translatedProfile);
            reset(translatedProfile);

            setValue('idCity', translatedProfile.idCity, { shouldValidate: true });
            setValue('idSpecialty', translatedProfile.idSpecialty, { shouldValidate: true });
        }

        setIsLoading(false);
    }, [basicProfile, formattedCities, formattedSpecialities, reset, setValue]);

    const _createErrorMessage = (errorMessage) => {
        setShowErrorModal(true);
        setErrorBody(errorMessage);
    };

    const closeErrorMessage = () => {
        setShowErrorModal(false);
        setErrorBody('');
    };

    const _createSuccessMessage = (errorMessage) => {
        setShowSuccessModal(true);
        setSuccessBody(errorMessage);
    };

    const saveProfileForm = (data) => {
        const objectToBeSaved = DoctorProfileInitialFormBO.prepareSaveObject(data);
        if (!objectToBeSaved) {
            _createErrorMessage('No se pudo obtener información válida para guardar. Inténtelo de nuevo.');
            return;
        }

        if (JSON.stringify(defaultValues) === '{}') {
            executeCreateBasicProfile(objectToBeSaved);
        } else {
            doUpdateBasicProfile(objectToBeSaved);
        }
    };

    const executeCreateBasicProfile = async (newBasicProfile) => {
        setIsLoading(true);
        const newProfile = await createBasicProfile(selectedUser.id, newBasicProfile, _createErrorMessage);

        if (newProfile) {
            _createSuccessMessage('Se ha guardado el registro con éxito!');
        }

        setIsLoading(false);
    };

    const doUpdateBasicProfile = async (updatedBasicProfile) => {
        setIsLoading(true);
        const newProfile = await updateBasicProfile(selectedUser.id, updatedBasicProfile, _createErrorMessage);

        if (newProfile) {
            _createSuccessMessage('Se ha actualizado el registro con éxito!');
        }

        setIsLoading(false);
    };

    const onCloseSuccessMessage = () => {
        setShowSuccessModal(false);
        setSuccessBody('');
        queryBasicProfile();
    };

    return {
        showErrorModal,
        setShowErrorModal,
        closeErrorMessage,
        showSuccessModal,
        setShowSuccessModal,
        successBody,
        errorBody,
        setErrorBody,
        register,
        handleSubmit,
        errors,
        watch,
        formattedCities,
        formattedSpecialities,
        isLoading,
        onCloseSuccessMessage,
        saveProfileForm,
        isEditing: JSON.stringify(defaultValues) !== '{}',
        defaultValues
    };
}
