export const CxWrap = ({ children }) => <div className="comm-box-shadow mt-10 p-4">{children}</div>;

export const CxCard = ({ children }) => <div className="py-6 px-6 space-y-6 sm:p-6">{children}</div>;

export const Line = () => <hr className="border border-black w-full" />;

export const CxTabButton = ({ label }) => (
  <button
    type="submit"
    className="bg-dl-primary-900 border border-transparent rounded-full shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dl-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dl-primary-700"
  >
    {label}
  </button>
);
