import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import createSchedules from '../api/createSchedules';
import updateSchedules from '../api/updateSchedules';
import { format } from 'date-fns';

export default function useDoctorSchedules(queryAllDoctorSchedules, selectedUser) {
    const [isLoading, setIsLoading] = useState(true);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState('');
    const [successBody, setSuccessBody] = useState('');
    const [defaultValues, setDefaultValues] = useState({});
    const [selectedSchedules, setSelectedSchedules] = useState(null);

    const {
        register,
        handleSubmit,
        formState: { errors, isDirty },
        watch,
        control,
        reset,
        setValue
    } = useForm(defaultValues);

    useEffect(() => {
        setIsLoading(true);

        if (selectedSchedules) {
            if (typeof selectedSchedules.openHours1 === 'string' && typeof selectedSchedules.openHours2 === 'string') {
                const now = new Date();
                now.setHours(selectedSchedules.openHours1.substr(0, selectedSchedules.openHours1.indexOf(':')));
                now.setMinutes(selectedSchedules.openHours1.substr(selectedSchedules.openHours1.indexOf(':') + 1));
                now.setSeconds(0);

                const now2 = new Date();
                now2.setHours(selectedSchedules.openHours2.substr(0, selectedSchedules.openHours2.indexOf(':')));
                now2.setMinutes(selectedSchedules.openHours2.substr(selectedSchedules.openHours2.indexOf(':') + 1));
                now2.setSeconds(0);

                selectedSchedules.openHours1 = now;
                selectedSchedules.openHours2 = now2;
            }
            setDefaultValues(selectedSchedules);
            reset(selectedSchedules);
            setValue('status', selectedSchedules.status ? 'true' : 'false', { shouldValidate: true });
            setValue('dayName', selectedSchedules.dayName, { shouldValidate: true });
            setValue('openHours1', selectedSchedules.openHours1, { shouldValidate: true });
            setValue('openHours2', selectedSchedules.openHours2, { shouldValidate: true });
        }

        setIsLoading(false);
    }, [reset, selectedSchedules, setValue]);

    const resetState = () => {
        setShowSuccessModal(false);
        setShowErrorModal(false);
        setErrorBody('');
        setSuccessBody('');
        setDefaultValues({});
        setSelectedSchedules(null);
        reset({ openHours1: '', openHours2: '' });
        setValue('status', '', { shouldValidate: true });
        setValue('dayName', '', { shouldValidate: true });
    };

    const _createErrorMessage = (errorMessage) => {
        setShowErrorModal(true);
        setErrorBody(errorMessage);
    };

    const closeErrorMessage = () => {
        setShowErrorModal(false);
        setErrorBody('');
    };

    const _createSuccessMessage = (errorMessage) => {
        setShowSuccessModal(true);
        setSuccessBody(errorMessage);
    };

    const saveSchedules = (data) => {
        data.openHours1 = format(data.openHours1, 'HH:mm');
        data.openHours2 = format(data.openHours2, 'HH:mm');
        if (!data) {
            _createErrorMessage('No se pudo obtener información válida para guardar. Inténtelo de nuevo.');
            return;
        }

        if (JSON.stringify(defaultValues) === '{}') {
            executeCreateSchedules(data);
        } else {
            const adjustedData = { ...data, idSchedule: data.id, status: data.status === 'true' };
            doUpdateSchedules(adjustedData);
        }
    };

    const executeCreateSchedules = async (newSchedules) => {
        setIsLoading(true);
        const createNewSchedules = await createSchedules(selectedUser.id, newSchedules, _createErrorMessage);

        if (createNewSchedules) {
            _createSuccessMessage('¡Se ha guardado el registro con éxito!');
        }

        setIsLoading(false);
    };

    const doUpdateSchedules = async (updatedSchedulesObject) => {
        setIsLoading(true);
        const executeUpdatedSchedules = await updateSchedules(
            selectedUser.id,
            updatedSchedulesObject,
            _createErrorMessage
        );

        if (executeUpdatedSchedules) {
            _createSuccessMessage('¡Se ha actualizado el registro con éxito!');
        }

        setIsLoading(false);
    };

    const onCloseSuccessMessage = () => {
        resetState();
        queryAllDoctorSchedules();
    };

    return {
        register,
        handleSubmit,
        errors,
        watch,
        control,
        reset,
        setValue,
        showErrorModal,
        setShowErrorModal,
        closeErrorMessage,
        showSuccessModal,
        setShowSuccessModal,
        successBody,
        errorBody,
        setErrorBody,
        setSelectedSchedules,
        onCloseSuccessMessage,
        saveSchedules,
        isEditing: JSON.stringify(defaultValues) !== '{}',
        defaultValues,
        isLoading,
        resetState,
        isDirty
    };
}
