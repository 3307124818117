import WhiteCardContainer from '../../../../../components/containers/forms/WhiteCardContainer';
import MotivesList from './components/motivesList/MotivesList';
import FormFieldsContainer from '../../../../../components/containers/forms/FormFieldsContainer';
import ProfileFormHeader from '../../ProfileFormHeader/ProfileFormHeader';
import FormInputField from '../../../../../components/formElements/FormInputField';
import HalfRow from '../../../../../components/formElements/HalfRow';
import useDoctorMotives from './hooks/useDoctorMotives';
import FullRow from '../../../../../components/formElements/FullRow';
import FormTextArea from '../../../../../components/formElements/FormTextArea';
import FormButton from '../../../../../components/formElements/FormButton';
import FormFieldsFooter from '../../../../../components/containers/forms/FormFieldsFooter';
import FormSelectDrop from '../../../../../components/formElements/FormSelectDrop';
import CustomRedAlert from '../../../../../components/alerts/customRedAlert/CustomRedAlert';
import CustomSuccessModal from '../../../../../components/alerts/customSuccessModal/CustomSuccessModal';
import FormResetButton from '../../../../../components/formElements/FormResetButton';

const statusOptions = [
    { id: true, label: 'Activo' },
    { id: false, label: 'Inactivo' }
];

export default function DoctorMotives(props) {
    const { motives, queryAllDoctorMotives, selectedUser } = props;

    const {
        register,
        errors,
        handleSubmit,
        saveMotives,
        isEditing,
        defaultValues,
        showErrorModal,
        errorBody,
        closeErrorMessage,
        showSuccessModal,
        successBody,
        onCloseSuccessMessage,
        setSelectedMotives,
        resetState,
        isDirty
    } = useDoctorMotives(queryAllDoctorMotives, selectedUser);

    return (
        <>
            <form action="#" method="POST" onSubmit={handleSubmit(saveMotives)}>
                <WhiteCardContainer>
                    <FormFieldsContainer>
                        <ProfileFormHeader
                            title={!isEditing ? 'Nuevo Motivo' : 'Actualizar Motivo'}
                            initialText={
                                'Ingresa posibles motivos de porque un usuario quisiera tener una consulta contigo.'
                            }
                        />
                        <div className="grid grid-cols-6 gap-6">
                            <HalfRow>
                                <FormInputField
                                    identifier={'motiveName'}
                                    label={'Título del motivo'}
                                    type={'text'}
                                    placeholder={'Ej. Primera consulta'}
                                    helperText={'Requerido'}
                                    registration={register('motiveName', { required: true })}
                                    errorMessage={errors.motiveName ? 'Este campo es requerido' : null}
                                />
                            </HalfRow>
                            <FullRow>
                                <FormTextArea
                                    label={'Detalle'}
                                    identifier={'motiveDescription'}
                                    rows={3}
                                    placeholder={'Ej. Consulta inicial para descubrir posibles causas de consulta'}
                                    helperText={'Requerido'}
                                    registration={register('motiveDescription', { required: true })}
                                    errorMessage={errors.motiveDescription ? 'Este campo es requerido' : null}
                                />
                            </FullRow>
                            {isEditing && (
                                <HalfRow>
                                    <FormSelectDrop
                                        label={'Estado'}
                                        identifier={'status'}
                                        options={statusOptions}
                                        defaultValue={defaultValues.status}
                                        helperText={'Requerido'}
                                        registration={register('status', { required: true })}
                                        errorMessage={errors.status ? 'Este campo es requerido' : null}
                                    />
                                </HalfRow>
                            )}
                        </div>
                    </FormFieldsContainer>
                    <FormFieldsFooter>
                        <FormResetButton onClick={resetState} label={'Cancelar'} />
                        <FormButton
                            label={isEditing ? 'Actualizar' : 'Guardar'}
                            type={'submit'}
                            disabled={isEditing && !isDirty}
                        />
                    </FormFieldsFooter>
                </WhiteCardContainer>
                {showErrorModal && (
                    <CustomRedAlert
                        open={true}
                        title={'Ocurrió un error.'}
                        bodyText={errorBody}
                        onClose={closeErrorMessage}
                    />
                )}

                {showSuccessModal && (
                    <CustomSuccessModal
                        title={isEditing ? 'Registro actualizado con éxito' : 'Nuevo registro guardado con éxito'}
                        bodyText={successBody}
                        buttonText={'Aceptar'}
                        buttonAction={onCloseSuccessMessage}
                        open={showSuccessModal}
                        onClose={onCloseSuccessMessage}
                    />
                )}
            </form>
            <div className="ml-1">
                <div className="ml-5">
                    <ProfileFormHeader title={'Motivos existentes'} />
                </div>
                {motives.length === 0 && <p className="ml-4 mt-4">No hay motivos para mostrar</p>}
                {motives.length > 0 && <MotivesList items={motives} onClick={setSelectedMotives} />}
            </div>
        </>
    );
}
