import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { UsersIcon, XMarkIcon, BeakerIcon, BriefcaseIcon, CalendarDaysIcon, SwatchIcon, UserGroupIcon, ClipboardDocumentListIcon, BookOpenIcon } from '@heroicons/react/24/outline';
import MainLogo from '../assets/images/dl-h-logo.png';
import { classNames } from '../utility/Cx';
import { Link, useLocation } from 'react-router-dom';

const Sidebar = ({ sidebarOpen, setSidebarOpen, userInfo }) => {
  const { pathname } = useLocation();

  let navigation = [
    { name: 'Pacientes', to: '/users', icon: UsersIcon, current: true },
    { name: 'Doctores', to: '/doctor-users', icon: BeakerIcon, current: false },
    { name: 'Perfiles Dr.', to: '/doctor-profiles', icon: BriefcaseIcon, current: false },
    { name: 'Citas', to: '/appointments', icon: CalendarDaysIcon, current: false },
    { name: 'Anuncios', to: '/ads', icon: SwatchIcon, current: false },
    userInfo?.idRole === 1 ? { name: 'Usuarios Web', to: '/web-users', icon: UserGroupIcon, current: false } : null,
    { name: 'Ciudades', to: '/cities', icon: UserGroupIcon, current: false },
    { name: 'Especialidades', to: '/specialities', icon: UserGroupIcon, current: false },
    { name: 'Idiomas', to: '/languages', icon: UserGroupIcon, current: false },
    { name: 'Suscripción', to: '/subscription', icon: ClipboardDocumentListIcon, current: false },
    { name: 'Manuales', to: '/manuals', icon: BookOpenIcon, current: false },
    { name: 'Categorías de documentos', to: '/doc-categories', icon: BookOpenIcon, current: false },
  ];

  const navItems = navigation.filter(Boolean).map((item) => {
    return (
      <Link
        key={item.name}
        to={item.to}
        className={classNames(item.to === pathname ? 'bg-blue-800 text-white' : 'text-blue-100 hover:bg-blue-600', 'group flex items-center px-2 py-2 text-base font-medium rounded-md')}
      >
        <item.icon className="mr-4 h-6 w-6 flex-shrink-0 text-blue-300" aria-hidden="true" />
        {item.name}
      </Link>
    );
  });

  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-40 md:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-blue-700 pt-5 pb-4">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex flex-shrink-0 items-center px-4">
                  <img className="h-8 w-auto" src={MainLogo} alt="DocLinkLogo" />
                </div>
                <div className="mt-5 h-0 flex-1 overflow-y-auto">
                  <nav className="space-y-1 px-2">{navItems}</nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            {/* Dummy element to force sidebar to shrink to fit close icon */}
            <div className="w-14 flex-shrink-0" aria-hidden="true"></div>
          </div>
        </Dialog>
      </Transition.Root>
      {/* Static sidebar for desktop */}
      <div className="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex flex-grow flex-col overflow-y-auto bg-dl-secondary">
          <div className="flex flex-shrink-0 items-center px-4 bg-white h-16">
            <img className="h-8 w-auto" src={MainLogo} alt="DocLinkLogo" />
          </div>
          <div className="mt-5 flex flex-1 flex-col">
            <nav className="flex-1 space-y-1 px-2 pb-4">{navItems}</nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
