import { useState } from 'react';
import WhiteCardContainer from '../../../../../components/containers/forms/WhiteCardContainer';
import FormFieldsContainer from '../../../../../components/containers/forms/FormFieldsContainer';
import FullRow from '../../../../../components/formElements/FullRow';
import PrimaryBadgeWithRemove from '../../../../../components/badges/PrimaryBadgeWithRemove';
import ControlledFormSelectDrop from '../../../../../components/formElements/controlled/ControlledFormSelectDrop';
import useSecondarySpecialties from './hooks/useSecondarySpecialties';
import CustomSuccessModal from '../../../../../components/alerts/customSuccessModal/CustomSuccessModal';
import CustomRedAlert from '../../../../../components/alerts/customRedAlert/CustomRedAlert';
import DeleteConfirmationModal from '../../../../../components/alerts/deleteConfirmationModal/DeleteConfirmationModal';

export default function SecondarySpecialties(props) {
    const {
        basicProfile,
        formattedSecondarySpecialties,
        secondarySpecialties,
        querySecondarySpecialities,
        selectedUser
    } = props;

    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const {
        executeSaveSecondarySpecialty,
        showSuccessModal,
        successBody,
        onCloseSuccessMessage,
        showErrorModal,
        errorBody,
        closeErrorMessage,
        onDeleteSecondarySpecialties
    } = useSecondarySpecialties(querySecondarySpecialities, selectedUser);

    if (!basicProfile.hasProfile) {
        return null;
    }

    const _onConfirmDelete = (id) => {
        onDeleteSecondarySpecialties(id);
        setOpenDeleteModal(false);
        querySecondarySpecialities();
    };

    return (
        <WhiteCardContainer>
            <FormFieldsContainer>
                <FullRow>
                    <div className="min-h-[calc(100vh_-_306px)]">
                        <ControlledFormSelectDrop
                            label={'Especialidades secundarias'}
                            identifier={'secondarySpecialties'}
                            options={formattedSecondarySpecialties}
                            value={''}
                            onChange={(e) => executeSaveSecondarySpecialty(e.target.value)}
                        />

                        {secondarySpecialties.length > 0 && (
                            <div className="mt-12">
                                {secondarySpecialties.map((secondary) => (
                                    <PrimaryBadgeWithRemove
                                        key={secondary.id}
                                        type={'large'}
                                        label={secondary.label}
                                        onRemove={() => {
                                            setOpenDeleteModal(secondary.id);
                                        }}
                                    />
                                ))}
                            </div>
                        )}
                        {secondarySpecialties.length === 0 && (
                            <div>
                                <p className="mt-1 text-sm text-gray-500">No ha seleccionado ninguna especialidad</p>
                            </div>
                        )}
                    </div>
                </FullRow>
                {showSuccessModal && (
                    <CustomSuccessModal
                        title={'Acción ejecutada con éxito!'}
                        bodyText={successBody}
                        buttonText={'Cerrar'}
                        buttonAction={onCloseSuccessMessage}
                        open={showSuccessModal}
                        onClose={onCloseSuccessMessage}
                    />
                )}

                {showErrorModal && (
                    <CustomRedAlert
                        open={true}
                        title={`Ocurrió un error.`}
                        bodyText={errorBody}
                        onClose={closeErrorMessage}
                    />
                )}
                {openDeleteModal && (
                    <DeleteConfirmationModal
                        open={Boolean(openDeleteModal)}
                        onClose={() => setOpenDeleteModal(false)}
                        onCancel={() => setOpenDeleteModal(false)}
                        onConfirm={() => _onConfirmDelete(openDeleteModal)}
                        title={'¿Desea eliminar esta especialidad secundaria?'}
                        bodyText={
                            'Esta acción es permanente. Si desea volver a agregarla tendrá que volver a seleccionar desde el menú.'
                        }
                    />
                )}
            </FormFieldsContainer>
        </WhiteCardContainer>
    );
}
