import { WhiteCardContainer, FormFieldsContainer } from '../../components';
import useWebUsers from './hooks/useWebUsers';
import { SimpleDataTable } from './components/SimpleDataTable';
import { ProfileFormHeader } from './components/ProfileFormHeader';
import WebUserDetails from './components/WebUserDetails/WebUserDetails';
import FormButton from '../../components/formElements/FormButton';

const WebUsers = () => {
  const { userTableInstance, isLoading, selectedUser, _closeUserView, onSuccessUserUpdate, onNewUser } = useWebUsers();

  return (
    <WhiteCardContainer>
      {selectedUser === null && (
        <FormFieldsContainer>
          <ProfileFormHeader title="Usuarios Doctores" />
          <div className="flex-row-reverse">
            <FormButton type="button" label="Nuevo Usuario" onClick={onNewUser} />
          </div>
        </FormFieldsContainer>
      )}
      {!isLoading && userTableInstance && selectedUser === null && <SimpleDataTable tableInstance={userTableInstance} />}

      {!isLoading && selectedUser !== null && <WebUserDetails selectedUser={selectedUser} onCancel={_closeUserView} onSuccessfulSave={onSuccessUserUpdate} />}
    </WhiteCardContainer>
  );
};

export default WebUsers;
