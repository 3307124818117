import { WhiteCardContainer, FormFieldsContainer } from '../../components';
import useUsers from './hooks/useUsers';
import { SimpleDataTable } from './components/SimpleDataTable';
import { ProfileFormHeader } from './components/ProfileFormHeader';
import UserDetails from './components/UserDetails/UserDetails';

const Users = () => {
  const { userTableInstance, isLoading, selectedUser, _closeUserView, userStatusCatalog, documentTypes, onSuccessUserUpdate } = useUsers();

  return (
    <WhiteCardContainer>
      {JSON.stringify(selectedUser) === '{}' && (
        <FormFieldsContainer>
          <ProfileFormHeader title="Pacientes" />
        </FormFieldsContainer>
      )}
      {!isLoading && userTableInstance && JSON.stringify(selectedUser) === '{}' && <SimpleDataTable tableInstance={userTableInstance} />}

      {!isLoading && JSON.stringify(selectedUser) !== '{}' && (
        <UserDetails
          selectedUser={selectedUser}
          onCancel={_closeUserView}
          onSuccessfulSave={onSuccessUserUpdate}
          selectionMode={'edit'}
          userStatusCatalog={userStatusCatalog}
          documentTypes={documentTypes}
        />
      )}
    </WhiteCardContainer>
  );
};

export default Users;
