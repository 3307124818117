import { LockClosedIcon } from '@heroicons/react/20/solid';
import MainLogo from '../../assets/images/dl-logo.png'
import {
  FullRow,
  FormInputField
} from '../../components';
import useLogin from './hooks/useLogin';

const Login = ({ performLogin }) => {
  const {
    register,
    onSubmit,
    errors,
    loginError
  } = useLogin(performLogin);

  return (
        <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
                <div className="w-full max-w-md space-y-8">
                    <div>
                        <img
                            className="mx-auto h-24 w-auto"
                            src={MainLogo}
                            alt="DocLinkLogo"
                        />
                        <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-blue-900">
                            Bienvenido
                        </h2>
                        <p className="mt-2 text-center text-sm text-gray-600">
                           Inicia sesión para continuar
                        </p>
                    </div>
                    <form className="mt-8 space-y-6" onSubmit={onSubmit}>
                        <input type="hidden" name="remember" defaultValue="true" />
                        <div className="-space-y-px flex flex-col gap-4">
                            <FullRow>
                              <FormInputField
                                  identifier="email"
                                  label="Correo electrónico"
                                  type="string"
                                  errorMessage={errors.email ? 'Este campo es requerido' : null}
                                  registration={register('email', {
                                      required: true
                                  })}
                              />
                            </FullRow>
                            <FullRow>
                              <FormInputField
                                    identifier="password"
                                    label="Contraseña"
                                    type="password"
                                    errorMessage={errors.password ? 'Este campo es requerido' : null}
                                    registration={register('password', {
                                        required: true
                                    })}
                                />
                            </FullRow>
                            <div>
                              { loginError ? <p className='text-sm text-red-700 text-light'>{loginError}</p> : null }
                            </div>
                        </div>

                        <div>
                            <button
                                type="submit"
                                className="group relative flex w-full justify-center rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                            >
                              <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                <LockClosedIcon className="h-5 w-5 text-white-500 group-hover:text-white-400" aria-hidden="true" />
                              </span>
                              Iniciar sesión
                            </button>
                        </div>
                    </form>
                </div>
            </div>
    );
};

export default Login;

