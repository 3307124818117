import Axios from './Axios';

const API_URL = '/cities/';

export const City = {
  getList: () => Axios.get(API_URL + 'getList'),
  getOne: (params) => Axios.get(API_URL + 'get', params),
  add: (values) => Axios.post(API_URL + 'add-city', values),
  update: (values) => Axios.post(API_URL + 'update', values),
  delete: (params) => Axios.delete(API_URL + params)
};
