import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { updatePassword } from '../api/updatePassword';

const usePasswordForm = () => {
    let newPassword = useRef({});
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
        reset
    } = useForm();

    newPassword.current = watch('newPassword', '');

    const validatePasswordConfirmation = (value) => value === newPassword.current;

    const onUpdatePassword = async ({ oldPassword, newPassword }) => {
        setIsLoading(true);

        const onFail = () => {
            setShowErrorModal(true);
        };

        const res = await updatePassword({ oldPassword, newPassword }, onFail);

        setIsLoading(false);

        if (res) setShowSuccessModal(true);
    };

    const onCloseErrorModal = () => setShowErrorModal(false);

    const onCloseSuccessModal = () => {
        setShowSuccessModal(false);
        reset();
    };

    const onCloseErrorMessage = () => {
        setShowErrorModal(false);
    };

    return {
        register,
        handleSubmit,
        errors,
        validatePasswordConfirmation,
        isLoading,
        showErrorModal,
        showSuccessModal,
        onUpdatePassword,
        onCloseErrorModal,
        onCloseSuccessModal,
        onCloseErrorMessage
    };
};

export default usePasswordForm;
