import { useForm } from 'react-hook-form';
import { useEffect, useRef, useState } from 'react';
import saveUpdatedUser from '../api/saveUpdatedUser';
import createNewUser from '../api/createNewUser';
import { format, parseISO } from 'date-fns';

export default function useUserDetails(selectedUser, onSuccessfulSave) {
    let newPassword = useRef({});
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState('');
    const [successBody, setSuccessBody] = useState('');
    const [isEditing, setIsEditing] = useState(null);

    const {
        register,
        handleSubmit,
        formState: { errors, isDirty },
        watch,
        // control,
        reset,
        setValue
    } = useForm();

    useEffect(() => {
        if (JSON.stringify(selectedUser) !== '{}') {
            setIsEditing(true);
            reset({ ...selectedUser, creation: format(parseISO(selectedUser.creation), 'yyyy-MM-dd H:mm:ss') });
        } else {
            setIsEditing(false);
        }
    }, [selectedUser, reset]);

    newPassword.current = watch('newPassword', '');

    const validatePasswordConfirmation = (value) => {
        if (!isEditing) {
            return value === newPassword.current;
        }

        return (isEditing && value === '') || value === newPassword.current;
    };

    const _createErrorMessage = (errorMessage) => {
        setShowErrorModal(true);
        setErrorBody(errorMessage);
    };

    const closeErrorMessage = () => {
        setShowErrorModal(false);
        setErrorBody('');
    };

    const _createSuccessMessage = (successMessage) => {
        setShowSuccessModal(true);
        setSuccessBody(successMessage);
    };

    const onCloseSuccessMessage = () => {
        setShowSuccessModal(false);
        setSuccessBody('');
        onSuccessfulSave();
    };

    const onSaveComplete = async (data) => {
        const executeSaveComplete = await createNewUser(
            {
                ...data,
                password: data.newPassword,
                status: data.status === 'true'
            },
            _createErrorMessage
        );

        if (executeSaveComplete) {
            _createSuccessMessage('¡Se ha actualizado el registro con éxito!');
        }
    };

    const onUpdate = async (data) => {
        const executeSaveComplete = await saveUpdatedUser(
            data.id,
            {
                ...data,
                ...(data.newPassword && { password: data.newPassword }),
                status: data.status === 'true'
            },
            _createErrorMessage
        );

        if (executeSaveComplete) {
            _createSuccessMessage('¡Se ha actualizado el registro con éxito!');
        }
    };

    const onSaveSubmit = (data) => {
        if (!isEditing) {
            onSaveComplete(data);
        } else {
            onUpdate(data);
        }
        return;
    };

    return {
        register,
        handleSubmit,
        errors,
        watch,
        reset,
        setValue,
        isDirty,
        closeErrorMessage,
        onCloseSuccessMessage,
        showSuccessModal,
        showErrorModal,
        errorBody,
        successBody,
        onSaveComplete,
        onSaveSubmit,
        isEditing,
        validatePasswordConfirmation
    };
}
